// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#user-assignmend-removed-assignment-removed-destroyed
const leadAssignmentRemoved = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:assignment_removed';

  stanzas.define({
    name: 'assignee',
    element: 'assignee',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'lead_assignment_removed',
    element: 'lead_assignment_removed',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      owner: types.textSub('owner'),
      assignee: types.textSub('assignee'),
      created_at: types.textSub('created_at'),
    },
  });
};

export default leadAssignmentRemoved;
