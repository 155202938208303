import { extendTheme } from '@hausgold/designsystem';

const maklerportalTheme = extendTheme({
  colors: {
    /*
     * These are colors from the Maklerportal and the scale is not necessarily
     * perfect.
     * HEADS UP: The shade scale logic for this color in MP is against the logic of the colors of the Designsystem. Designsystem: higher number === darker, MP: higher number === brighter.
     */
    darkBlue: {
      100: '#1a242b', // Dimmed header background color, when mobile sidebar is open.
      200: '#314553', // Darker sidebar background color (for the logo bg)
      300: '#335365', // Sidebar background color.
      400: '#0078bd', // Header background & main color (buttons, links, etc.). Note: If you see #0071b8 somewhere, use this instead; it's 99% the same!
      500: '#1984c2', // Color used in gradients together with 400.
    },
  },
  styles: {
    global: {
      body: {
        bgGradient: 'linear(to-t, #f0f2fa, #f5f7fc 100%)',
        '@media print': {
          WebkitPrintColorAdjust: 'exact',
          background: 'white',
        },
      },
    },
  },
  breakpoints: {
    '2xl': '90rem',
    '3xl': '100rem',
  },
  components: {
    Container: {
      baseStyle: {
        px: [5, 10],
      },
    },
  },
});

export default maklerportalTheme;
