/* eslint-disable @typescript-eslint/naming-convention */

import { config as appConfig } from 'app-config';
import { ID, GlobalId } from 'app-graphql';
import {
  computeLocalBundle,
  getAccessToken,
} from 'app-services/models/rest/bundle';

/**
 * The response object of `PROPERTY_API/v1/password/validate`.
 * As this API is not integrated in GraphQL we need to create the type on our own.
 */
export type ValidatePasswordQueryProps = {
  acceptable: boolean;
  compromised: boolean;
  score: number;
  original_password: string;
  sanitized_password: string;
  length: number;
  feedback: {
    warnings: string[];
    suggestions: string[];
  };
};

/**
 * Request the open api of Identity to get information about the security of given password.
 */
export const validatePasswordQuery = async (
  password: string
): Promise<ValidatePasswordQueryProps> =>
  fetch(`${appConfig.endpoints.identityApi}/v1/password/validate`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password,
      locale: 'de-DE',
    }),
  }).then((_response) => _response.json());

export type CreateNotificationTokenMutationVariables = {
  userId: ID;
  token: string;
  appId: ID;
  projectId: ID;
};
export type CreateNotificationMutationProps = {
  app_id: ID;
  gid: GlobalId;
  id: ID;
  project_id: ID;
  token: ID;
  user_gid: GlobalId;
  user_id: ID;
};

export type CreateNotificationMutationErrorProps = {
  code: string;
  message: string;
  reason: string | null;
};
/**
 * Request the Identity API to register a firebase cloud messaging token.
 * In contrast to GraphQL, in case the token is already used we get the `NotificationTokenGlobalIdOrUuid`
 * to remove it within `reason`, a field that GraphQl not return.
 */
export const createNotificationTokenMutation = async (
  payload: CreateNotificationTokenMutationVariables
): Promise<
  CreateNotificationMutationProps | CreateNotificationMutationErrorProps
> =>
  fetch(`${appConfig.endpoints.identityApi}/v1/notification_tokens`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken(computeLocalBundle())}`,
    },
    body: JSON.stringify({
      user_id: payload.userId,
      project_id: payload.projectId,
      app_id: payload.appId,
      token: payload.token,
    }),
  }).then((_response) => _response.json());
