import { concatenateStrings } from 'app-services/utils/global';

/**
 * Formats full name
 *
 * @param {object=} preferencesSet
 * @returns {string|undefined}
 */
export const formatFullName = (preferencesSet) =>
  concatenateStrings(
    [preferencesSet?.firstName, preferencesSet?.lastName],
    'space'
  );
