import {
  computeLocalBundle,
  getAccessToken,
  getRefreshToken,
  getUserGid,
  getUserId,
} from '../models/rest/bundle';
import { getOverwriteToken } from '../models/rest/overwriteToken';

/**
 * Returns the user's JWT bundle
 *
 * @returns {object|null}
 */
export const useBundle = () => computeLocalBundle();

/**
 * Returns the user's access token
 * ... OR the overwrite token (given via ?token=X via query parameter, e.g. in the broker profile)
 *
 * @returns {string|undefined}
 */
export const useAccessToken = () => {
  const bundle = useBundle();

  return getOverwriteToken() || getAccessToken(bundle);
};

/**
 * Returns the user's refresh token
 * @returns {string|undefined}
 */
export const useRefreshToken = () => {
  const bundle = useBundle();

  return getRefreshToken(bundle);
};

/**
 * Returns the user's id
 * @returns {import('../../../graphql.source').ID|undefined}
 */
export const useUserId = () => {
  const bundle = useBundle();

  return getUserId(bundle);
};

/**
 * Returns the user's gid
 * @returns {import('../../../graphql.source').GlobalId|undefined}
 */
export const useUserGid = () => {
  const bundle = useBundle();

  return getUserGid(bundle);
};

/**
 * Returns true if the user is authenticated
 * @returns {boolean}
 */
export const useIsAuthenticated = () => {
  const bundle = useBundle();

  return !!bundle;
};

/**
 * Returns true if the user is not authenticated
 * @returns {boolean}
 */
export const useIsNotAuthenticated = () => {
  const bundle = useBundle();

  return !bundle;
};
