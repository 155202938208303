// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-access-was-permitted-lead-access-permitted
const leadAccessPermitted = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:access_permitted';

  stanzas.define({
    name: 'lead_access_permitted',
    element: 'lead_access_permitted',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      created_at: types.textSub('created_at'),
      supervisor: types.textSub('supervisor'),
    },
  });
};

export default leadAccessPermitted;
