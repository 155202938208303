/**
 * These definitions are shared by multiple stanzas.
 * https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber
 */
const shared = (client, stanzas) => {
  const types = stanzas.utils;
  const OWNER_NS = 'xmpp:hausgold:shared:owner';
  const RECEIVER_NS = 'xmpp:hausgold:shared:receiver';
  const CREATED_NS = 'xmpp:hausgold:shared:created_at';
  const SUPERVISOR_NS = 'xmpp:hausgold:shared:supervisor';

  /**
   * The owner of a lead/property
   */
  stanzas.define({
    name: 'owner',
    element: 'owner',
    namespace: OWNER_NS,
    fields: {
      id: types.attribute('id'),
      value: types.text(),
    },
  });

  /**
   * The receiver of a lead/property.
   * The person who initially got it.
   */
  stanzas.define({
    name: 'receiver',
    element: 'receiver',
    namespace: RECEIVER_NS,
    fields: {
      id: types.attribute('id'),
      value: types.text(),
    },
  });

  /**
   * The date of a related appointment or similar.
   * This is not the creation date of the message!
   */
  stanzas.define({
    name: 'created_at',
    element: 'created_at',
    namespace: CREATED_NS,
    fields: {
      format: types.attribute('format'),
      value: types.text(),
    },
  });

  /**
   * The person who is in charge of.
   */
  stanzas.define({
    name: 'supervisor',
    element: 'supervisor',
    namespace: SUPERVISOR_NS,
    fields: {
      id: types.attribute('id'),
      value: types.text(),
    },
  });

  /**
   * `multiplexed` messages
   */
  stanzas.define({
    name: 'multiplexed',
    element: 'multiplexed',
    namespace: 'jabber:client',
  });
};

export default shared;
