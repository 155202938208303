import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, dataAttr, Link } from '@hausgold/designsystem';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

/**
 * @typedef {Object} SidebarMenuLink
 * @property {string} title The text & title of the menu item
 * @property {string} href The href of the menu item
 * @property {string} activeOn The `href` the item should also be active on
 * @property {React.ReactHTMLElement} icon The icon component of the menu item
 */

/**
 * Sidebar menu link
 *
 * Renders a single sidebar menu link.
 *
 * @param {SidebarMenuLink} props
 */
const SidebarMenuLink = forwardRef((props, ref) => {
  const { href, as, icon, title, activeOn, ...rest } = props;
  const router = useRouter();

  const hoverAndActiveStyles = {
    color: 'white',
    bg: 'darkBlue.200',
    '& .chakra-icon': {
      color: '#fff',
    },
  };

  const isActive =
    router.pathname === href || router.pathname.startsWith(activeOn);

  return (
    <Link
      ref={ref}
      href={href}
      as={as}
      display="flex"
      alignItems="center"
      px={4}
      py={4}
      w="100%"
      colorScheme="gray"
      color="whiteAlpha.700"
      title={title}
      // Special fontSize to fit Maklerportal style.
      fontSize="13px"
      transitionProperty="common"
      _hover={hoverAndActiveStyles}
      _active={hoverAndActiveStyles}
      data-active={dataAttr(isActive)}
      textTransform="uppercase"
      fontWeight="semibold"
      data-testid="menuItem"
      {...rest}
    >
      {icon && (
        <Box as="span" display="flex" px={1} mr={2}>
          {React.cloneElement(icon, {
            boxSize: 5,
            color: 'inherit',
            transitionProperty: 'color',
            transitionDuration: '150ms',
          })}
        </Box>
      )}

      {title}
    </Link>
  );
});

SidebarMenuLink.propTypes = {
  title: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.node,
  activeOn: PropTypes.string,
};

SidebarMenuLink.defaultProps = {
  href: '',
  as: null,
  icon: null,
  activeOn: null,
};

/**
 * @typedef {Object & SidebarMenuLink} SidebarMenuItem
 * @property {import('next/link').LinkProps['href']} href The href of the menu item
 */

/**
 * Sidebar menu item
 *
 * Decides on rendering a `next/link` or not depending on if the item is
 * a button or a link.
 *
 * @param {SidebarMenuItem} props
 */
const SidebarMenuItem = ({ href, as, ...rest }) => {
  if (as === 'button') {
    return <SidebarMenuLink as={as} {...rest} />;
  }

  return (
    <NextLink href={href} passHref>
      <SidebarMenuLink {...rest} />
    </NextLink>
  );
};

SidebarMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.node,
  activeOn: PropTypes.string,
};

SidebarMenuItem.defaultProps = {
  href: '',
  as: null,
  icon: null,
  activeOn: null,
};

export default SidebarMenuItem;
