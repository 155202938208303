module.exports = {
  extends: ['plugin:@next/next/recommended'],
  overrides: [
    {
      files: 'next.config.js',
      rules: {
        /**
         * Allows imports from `devDependencies`.
         * https://github.com/import-js/eslint-plugin-import/blob/main/docs/rules/no-extraneous-dependencies.md
         */
        'import/no-extraneous-dependencies': 'off',
      },
    },
  ],
};
