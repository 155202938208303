import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isLocal } from 'app-config';
import {
  formatDate,
  formatNumber,
  formatPrice,
  formatTime,
} from 'app-services/utils/formatters';
import { resources, defaultNS, namespaces } from '../../../i18n';

i18n
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  /*
   * Detect user language
   * learn more: https://github.com/i18next/i18next-browser-languageDetector
   */
  .use(LanguageDetector)
  /*
   * Init i18next
   * for all options read: https://www.i18next.com/overview/configuration-options
   */
  .init(
    {
      debug: isLocal,
      fallbackLng: 'de',
      supportedLngs: ['de'],
      ns: namespaces,
      defaultNS,
      resources,
      react: {
        useSuspense: true,
        bindI18n: 'languageChanged',
      },
      interpolation: {
        /*
         * Defines custom formatter functions
         * Starting with i18next>=21.3.0 we could use the built-in formatting functions based on the Intl API.
         * https://www.i18next.com/translation-function/formatting
         */
        // @ts-ignore
        format(value: string | number | Date, format, lng) {
          if (typeof value === 'string') {
            /*
             * Formats a string to uppercase
             * Example: {{value, uppercase}}
             */
            if (format === 'uppercase') return value.toUpperCase();

            /*
             * Formats a string to lowercase
             * Example: {{value, lowercase}}
             */
            if (format === 'lowercase') return value.toLowerCase();
          }

          if (value instanceof Date) {
            /*
             * Formats date to dd.MM.yyy
             * Example: {{value, time}}
             */
            if (format === 'date') return formatDate(value) || value;

            /*
             * Formats time to HH:mm
             * Example: {{value, time}}
             */
            if (format === 'time') return formatTime(value) || value;

            /*
             * Date formatting with format given via `format`.
             * Example: {{value, dd.MM.yy}} or {{value, dd.MM.yyyy HH:mm
             */
            return formatDate(value, format) || value;
          }

          if (typeof value === 'number') {
            /*
             * Formats currency
             * Example: {{value, currency}}
             */
            if (format === 'currency') {
              return formatPrice(parseFloat(String(value)), undefined, lng);
            }

            /*
             * Formats a number
             * Example: {{value, number}}
             */
            if (format === 'number') {
              return formatNumber(parseFloat(String(value)), undefined, lng);
            }
          }

          return value;
        },

        // Not needed, as React escapes by default.
        escapeValue: false,
      },
    },
    // This is needed to get rid of a type warning
    () => {}
  );

export default i18n;
