import PropTypes from 'prop-types';
import NextLink from 'next/link';
import { HStack, Link } from '@hausgold/designsystem';
import { useTranslation } from 'react-i18next';

/**
 * Legal links
 * Displays all our legal links in a `HStack`.
 */
const LegalLinks = ({ linkProps, ...props }) => {
  const { t } = useTranslation();

  return (
    <HStack {...props}>
      <NextLink href="/legal/notice" passHref>
        <Link {...linkProps} target="_blank">
          {t('legalNotice.title')}
        </Link>
      </NextLink>

      <NextLink href="https://www.hausgold.de/datenschutz/" passHref>
        <Link {...linkProps} target="_blank">
          {t('privacyPolicy.title')}
        </Link>
      </NextLink>
    </HStack>
  );
};

LegalLinks.propTypes = {
  linkProps: PropTypes.object,
};

LegalLinks.defaultProps = {
  linkProps: null,
};

export default LegalLinks;
