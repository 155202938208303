import { createContext, useContext } from 'react';

/**
 *
 * Header height context
 *
 * Responsible for storing the header's height.
 *
 * @type {React.Context<{headerHeight: null|number|string, setHeaderHeight: (number) => void}>}
 */
export const HeaderHeightContext = createContext({
  // Set the expected headerHeight to prevent FOUCs in ~99% of cases.
  headerHeight: '48px',
  setHeaderHeight: () => {},
});

/**
 * UseHeaderHeight
 *
 * Returns the value and the `headerHeightContext`'s setter function
 * like `useState`.
 *
 * Example:
 *   const [headerHeight, setHeaderHeight] = useHeaderHeight();
 *
 * @return {[null|number|string, function]}
 */
export const useHeaderHeight = () => {
  const { headerHeight, setHeaderHeight } = useContext(HeaderHeightContext);

  return [headerHeight, setHeaderHeight];
};
