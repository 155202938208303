import { format, parseISO } from 'date-fns';
import { getI18n } from 'react-i18next';

/**
 * Formats a date by default notation, e.g. 01.01.2021
 * @param date
 * @param formatString
 * @returns {string|null}
 */
export const formatDate = (date, formatString = 'dd.MM.yyy') => {
  if (!date) return null;

  try {
    return format(
      typeof date === 'string' ? parseISO(date) : new Date(date),
      formatString
    );
  } catch {
    return null;
  }
};

/**
 * Formats time by default notation, e.g. 15:00
 * @param date
 * @param formatString
 * @returns {string|null}
 */
export const formatTime = (date, formatString = 'HH:mm') =>
  formatDate(date, formatString);

/**
 * Formats a number
 *
 * Default: Current i18n language.
 *
 * @param {number} value
 * @param {Intl.NumberFormatOptions=} options
 * @param {string=} language
 * @return {string}
 */
export const formatNumber = (value, options, language = getI18n().language) => {
  if (typeof value !== 'number') return null;

  return new Intl.NumberFormat(language, {
    ...options,
  }).format(value);
};

/**
 * Formats a number in m²
 *
 * Default: Current i18n language.
 *
 * We use a custom function here because Intl.Numberformat only supports a subset
 * of the available units in UTS #35.
 *
 * @see https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#unit
 *
 * @param {number | undefined | null | string} value
 * @param {Pick<Intl.NumberFormatOptions, 'minimumIntegerDigits' | 'minimumFractionDigits' | 'maximumFractionDigits'> | null | undefined} [options]
 * @param {string=} language
 * @return {string|null}
 */
export const formatSquaredMeter = (
  value,
  options,
  language = getI18n().language
) => {
  if (typeof value !== 'number') return null;

  return new Intl.NumberFormat(language, {
    ...options,
    style: 'unit',
    unitDisplay: 'short',
    unit: 'meter',
  })
    .formatToParts(value)
    .map((part) => {
      if (part.type === 'unit') {
        return 'm²';
      }

      return part.value;
    })
    .join('');
};

/**
 * Formats a price
 *
 * Default: Current i18n language.
 *
 * @param {number} value
 * @param {Intl.NumberFormatOptions=} options
 * @param {string=} language
 * @return {string}
 */
export const formatPrice = (value, options, language) =>
  formatNumber(
    value,
    { style: 'currency', currency: 'EUR', ...options },
    language
  );

/**
 * If the provided value is undefined|empty|null we want to
 * return a message for the user so he/she knows that there
 * is no value available.
 *
 * If the provided value is not undefined|empty|null we simply
 * return the value
 *
 * @param {string | undefined | null} value Value to check for validity
 * @param {string} defaultI18nKey Custom i18n key for custom messages if needed. Defaults to: "common:notSpecified"
 * @return {i18n} - i18n key string
 */
export const formatEmpty = (value, defaultI18nKey = 'common:notSpecified') => {
  if (value === undefined || value === '' || value === null)
    return getI18n().t(defaultI18nKey);

  return value;
};

/**
 * Formats a string input into a number
 * Used for formatting prices and commissions before sending it to the backend.
 *
 * @param {string | undefined} price
 * @returns {number | null}
 */
export const formatStringPriceToNumber = (price) => {
  if (price && !Number.isNaN(parseFloat(price))) {
    return parseFloat(price.replace(/\.|\s/g, '').replace(',', '.'));
  }

  return null;
};
