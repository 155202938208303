import { config } from 'app-config';

/**
 * Maklerportal API deployment
 * @description Fetches the Maklerportal API deployment information, used to determine if the API is online.
 */
export const getApiDeployment = async () =>
  fetch(`${config.endpoints.maklerportalApi}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    // Get response and parse json of the deployment information
  }).then((response) => response.json().then((json) => ({ json, response })));
