export type EventObject =
  | 'CreditPurchase'
  | 'LeadPurchase'
  | 'LeadBid'
  | 'LeadReject'
  | 'LeadPause'
  | 'LeadCancel'
  | 'LeadResume'
  | 'NotificationCenter'
  | 'PushNotification'
  | 'Print'
  | 'OpenImmo'
  | 'Email'
  | 'Phone'
  | 'PWAModal'
  | 'PWASystem'
  | 'ResetPassword'
  | 'Contract'
  | 'Navigation';

export type EventVerb =
  // General
  | 'Interacted'
  | 'Seen'
  // Form / Modal / Menu specific
  | 'Opened'
  | 'Submitted'
  | 'Aborted'
  // Payment specific
  | 'OverviewAcknowledged'
  | 'PackageSelected'
  | 'PaymentSelected'
  | 'Purchased';

/**
 * Helper to construct event names according to the guideline.
 *
 * The function should prevent typing errors and a comfortable overview about existing ones.
 * All event names consists of an Object and a Verb connected by a space.
 * Each Object and each Verb is written in capitalized camelCase and Verbs are written in past form.
 *
 * @example CreditPurchase Aborted
 *
 * @param object The meaningful topic the event belongs to.
 * @param verb The action the user executed.
 * @param value An individual parameter which is significant for analytics. As every combination creates a new event the possibilities should be few.
 */
export const eventBuilder = (
  object: EventObject,
  verb: EventVerb,
  value?: string
) =>
  (object?.length &&
    verb?.length &&
    [object, verb, value].filter(Boolean).join(' ')) ||
  '';

/**
 * List of all available groups used for segment tracking.
 *
 * The list should prevent typing errors and a comfortable overview about existing ones.
 * All properties are written in snake_case.
 */
export const GROUP = {
  accountBalance: 'account_balance',
  leadOffer: 'lead_offer',
  leadManage: 'lead_manage',
  notification: 'notification',
  accountManage: 'account_manage',
  pwa: 'pwa',
  other: 'other',
};
