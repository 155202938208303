/* eslint-disable camelcase */
import { config } from 'app-config';
import ApiClient from 'app-lib/apiV2';

/*
 * Time it takes for an access token to expire.
 */
export const tokenExpireTime = 60 * 60; // 60 minutes

// https://identity-api.hausgold.de/sandbox
class IdentityApi extends ApiClient {
  /**
   * Login and create JWT token
   * https://identity-api.hausgold.de/sandbox#!/jwt/postV1JwtPassword
   *
   * @param email
   * @param password
   * @param masquerade
   * @param masquerade.email
   * @param masquerade.id
   * @param expiresIn
   * @returns {Promise<void>}
   */
  login = ({ email, password, masquerade, expiresIn = tokenExpireTime }) =>
    this.request({
      url: 'v1/jwt/password',
      method: 'post',
      data: {
        email,
        password,
        masquerade,
        expires_in: expiresIn,
        context: 'broker',
      },
    });

  /**
   * Forgot password workflow
   * https://identity-api.hausgold.de/sandbox#!/users/postV1UsersWorkflowsRecover
   *
   * @param email
   * @param id
   * @param metadata
   * @param metadata.endpoint
   * @returns {Promise<void>}
   */
  forgotPassword = ({ email, id, metadata }) =>
    this.request({
      url: 'v1/users/workflows/recover',
      method: 'post',
      data: {
        email,
        id,
        metadata: {
          endpoint: metadata?.endpoint,
        },
      },
    });

  /**
   * Reset password workflow
   * https://identity-api.hausgold.de/sandbox#!/users/postV1UsersWorkflowsRecovered
   *
   * @param email
   * @param id
   * @param token
   * @param password
   * @returns {Promise<void>}
   */
  resetPassword = ({ email, id, token, password }) =>
    this.request({
      url: 'v1/users/workflows/recovered',
      method: 'post',
      data: {
        email,
        id,
        token,
        password,
      },
    });

  /**
   * Start user activation workflow
   * https://identity-api.hausgold.de/sandbox#!/users/postV1UsersWorkflowsActivate
   *
   * @param email
   * @param id
   * @param metadata
   * @param metadata.endpoint
   * @returns {Promise<void>}
   */
  startUserActivation = ({ email, id, metadata }) =>
    this.request({
      url: 'v1/users/workflows/activate',
      method: 'post',
      data: {
        email,
        id,
        metadata: {
          endpoint: metadata?.endpoint,
        },
      },
    });

  /**
   * Finish user activation workflow
   * https://identity-api.hausgold.de/sandbox#!/users/postV1UsersWorkflowsActivated
   *
   * @param email
   * @param id
   * @param token
   * @param password
   * @returns {Promise<void>}
   */
  finishUserActivation = ({ email, id, token, password }) =>
    this.request({
      url: 'v1/users/workflows/activated',
      method: 'post',
      data: {
        email,
        id,
        token,
        password,
      },
    });

  /**
   * Create notification token
   * https://identity-api.hausgold.de/sandbox#!/notification_tokens/postV1NotificationTokens
   *
   * @param token
   * @param appId
   * @param userId
   * @param projectId
   * @param accessToken
   * @returns {Promise<void>}
   */
  createNotificationToken = ({
    token,
    appId,
    userId,
    projectId,
    accessToken,
  }) =>
    this.requestWithAccessToken({
      url: 'v1/notification_tokens',
      method: 'post',
      data: {
        token,
        app_id: appId,
        user_id: userId,
        project_id: projectId,
      },
      accessToken,
    });

  /**
   * Delete notification token
   * https://identity-api.hausgold.de/sandbox#!/notification_tokens/deleteV1NotificationTokensId
   *
   * @param id
   * @param accessToken
   * @returns {Promise<void>}
   */
  deleteNotificationToken = ({ id, accessToken }) =>
    this.requestWithAccessToken({
      url: 'v1/notification_tokens/{id}',
      method: 'delete',
      data: { id },
      accessToken,
    });
}

const identityApi = new IdentityApi({
  baseUrl: config.endpoints.identityApi,
});

export default identityApi;
