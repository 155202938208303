import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
      refetchOnWindowFocus: true,
      structuralSharing: true,
      cacheTime: 1000 * 60 * 60 * 24, // 24H
    },
  },
});

export default queryClient;
