// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-due-date-for-accepting-expired-lead-locked
const leadLocked = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:locked';

  stanzas.define({
    name: 'lead_locked',
    element: 'lead_locked',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      created_at: types.textSub('created_at'),
    },
  });
};

export default leadLocked;
