/*
 * `?token` query parameter
 * Used for temporarily overwriting the access token.
 */

/* eslint-disable import/prefer-default-export */

/**
 * Returns the overwrite token from the URL query parameter `token`.
 *
 * This is used instead of the bundle access token when it is available, e.g. for the broker profile. See
 * `useAccessToken`.
 *
 * @returns {string|null}
 */
export const getOverwriteToken = () => {
  const urlParameter = new URLSearchParams(global?.location?.search);

  try {
    return urlParameter.get('token');
  } catch (error) {
    return null;
  }
};
