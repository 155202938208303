/* eslint-disable react/no-unknown-property */
import { useEffect, useMemo } from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  LogoMaklerportal,
  MinimalLayout as MinimalLayoutDs,
  useBreakpointValue,
  useDisclosure,
  VStack,
  useTheme,
} from '@hausgold/designsystem';
import PropTypes from 'prop-types';
import { useHeaderHeight } from 'app-utils/contexts/headerHeight';
import Sidebar from 'app-components/page/Sidebar';
import Header, { WithWcoHeader } from 'app-components/page/Header';
import Main from 'app-components/page/Main';
import LegalLinks from 'app-components/misc/LegalLinks';
import PageSlide from 'app-components/page/PageSlide';
import { SIDEBAR_WIDTH } from 'app-utils/constants/ui';
import Head from 'next/head';
import usePWADisplayMode from 'app-utils/hooks/usePWADisplayMode';

/**
 * Minimal layout
 *
 * Layout to center the content for special "standalone" sites like all
 * authentication related pages
 *
 * @param {import('@hausgold/designsystem').BoxProps} props
 */
export const MinimalLayout = (props) => (
  <>
    <Head>
      <meta key="theme-color" name="theme-color" content="#f5f7fc" />
    </Head>

    <MinimalLayoutDs
      {...props}
      logo={<LogoMaklerportal />}
      footer={<LegalLinks spacing={10} />}
    />
  </>
);

MinimalLayout.propTypes = {
  showLogo: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
};

MinimalLayout.defaultProps = {
  showLogo: true,
  children: null,
};

/**
 * Spacey layout
 *
 * Displays the page, just without a sidebar, allowing full sized content and
 * a lot of space.
 *
 * @param {Object & import('@hausgold/designsystem').BoxProps} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 */
export const SpaceyLayout = ({
  title,
  children,
  showSearchBar,
  showNotificationCenter,
  ...rest
}) => {
  const [headerHeight] = useHeaderHeight();

  return (
    <>
      <Head>
        <meta key="theme-color" name="theme-color" content="#f5f7fc" />
      </Head>

      <Header
        pageTitle={title}
        showSearchBar={showSearchBar}
        showNotificationCenter={showNotificationCenter}
      />
      <VStack
        alignItems="flex-start"
        justifyContent="space-between"
        w="100%"
        minH="100vh"
        style={{
          paddingTop: headerHeight,
        }}
      >
        <Main {...rest}>{children}</Main>
      </VStack>
    </>
  );
};

SpaceyLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
  title: PropTypes.string,
  showSearchBar: PropTypes.bool,
  showNotificationCenter: PropTypes.bool,
};

SpaceyLayout.defaultProps = {
  children: null,
  title: null,
  showSearchBar: false,
  showNotificationCenter: false,
};

/**
 * Sidebar layout
 *
 * Displays the page with a desktop and mobile sidebar.
 *
 * @param {Object & import('@hausgold/designsystem').BoxProps} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 */
export const SidebarLayout = ({
  title,
  children,
  showSearchBar,
  showNotificationCenter,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [headerHeight] = useHeaderHeight();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  useEffect(() => {
    if (!isMobile) onClose();
  }, [isMobile, onClose]);

  const {
    colors: { darkBlue },
  } = useTheme();

  const { isWindowControlsOverlay } = usePWADisplayMode();

  // Changes the theme color according to different app display states.
  const content = useMemo(() => {
    let newContent = '#f5f7fc';
    if (isMobile || (!isMobile && isWindowControlsOverlay)) {
      newContent = darkBlue['200'];
    }

    /**
     * Sidebar is open
     * We dim the theme-color to match the darker header color beneath the window-control-elements.
     */
    if (isOpen) {
      newContent = darkBlue['100'];
    }

    return newContent;
  }, [isMobile, isWindowControlsOverlay, isOpen, darkBlue]);

  return (
    <>
      <Head>
        <meta key="theme-color" name="theme-color" content={content} />
      </Head>

      <style jsx global>{`
        body {
          overflow-x: hidden;
        }
      `}</style>
      {isWindowControlsOverlay && (
        <WithWcoHeader
          headerProps={{
            pageTitle: title,
            drawerIsOpen: isOpen,
            drawerOnOpen: onOpen,
            showSearchBar,
            showNotificationCenter,
          }}
        />
      )}

      <Flex>
        <Sidebar
          display={{
            base: 'none',
            md: 'flex',
          }}
        />
        <PageSlide
          in={isOpen}
          flexGrow={1}
          flexDirection="column"
          minH="100vh"
          w="full"
        >
          {!isWindowControlsOverlay && (
            <Header
              pageTitle={title}
              drawerIsOpen={isOpen}
              drawerOnOpen={onOpen}
              showSearchBar={showSearchBar}
              showNotificationCenter={showNotificationCenter}
            />
          )}

          <Main
            height={`calc(100% - ${headerHeight})`}
            /*
             * Fix px-value needs to be the same as in `<Sidebar/>`.
             * We need a fix overall-width px-value so Browsers can calculate relative width of child elements
             * like flex correctly instead of a "relative" percentage-value.
             */
            width={{
              base: '100vW',
              md: `calc(100vw - ${SIDEBAR_WIDTH})`,
            }}
            {...rest}
          >
            {children}
          </Main>
        </PageSlide>
      </Flex>
      {/* Mobile drawer */}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        size="xs"
        isFullHeight
        display={{
          base: 'block',
          md: 'none',
        }}
      >
        {/* This box lays under the sidebar and content header and prevents a 1px flicker while the drawer slides. */}
        <Box
          zIndex="hide"
          top={0}
          left={0}
          position="absolute"
          width="100vw"
          height={12}
          bgColor="darkBlue.200"
        />
        {/* Move the drawer overlay down and make it smaller, so it's not dimming the header */}
        <DrawerOverlay
          top={headerHeight}
          height={`calc(100vh - ${headerHeight})`}
        />
        <DrawerContent
          boxShadow="none"
          bg="none"
          top="initial"
          bottom={0}
          borderRadius="none"
          maxWidth={SIDEBAR_WIDTH}
        >
          {/* minHeight with dvh needed here so that the sidebar menu can adapt to browser ui changes. When opening the drawer the ui maybe minimized or maximized, depending on what direction the user scrolled before.  */}
          <Sidebar minH="100dvh" />
        </DrawerContent>
      </Drawer>
    </>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
  title: PropTypes.string,
  showSearchBar: PropTypes.bool,
  showNotificationCenter: PropTypes.bool,
};

SidebarLayout.defaultProps = {
  children: null,
  title: null,
  showSearchBar: false,
  showNotificationCenter: false,
};
