import Cookies from 'js-cookie';
import cookies from 'app-lib/cookies';
import { BUNDLE_STORAGE_KEY } from 'app-utils/constants/storageKeys';
import appsignal, { CATEGORIES } from 'app-lib/appsignal';

// JWT Authorization Bundle (Local Storage / Query Parameter & Identity API)

/**
 * Returns the user
 *
 * @param bundle
 * @returns {object | undefined}
 */
export const getUser = (bundle) => bundle?.user;

/**
 * Returns the user ID
 *
 * @param bundle
 * @returns {import('../../../graphql.source').ID | undefined}
 */
export const getUserId = (bundle) => getUser(bundle)?.id;

/**
 * Returns the user GID
 *
 * @param bundle
 * @returns {import('../../../graphql.source').GlobalId | undefined}
 */
export const getUserGid = (bundle) => getUser(bundle)?.gid;

/**
 * Returns the user e-mail
 *
 * @param bundle
 * @returns {string | undefined}
 */
export const getUserEmail = (bundle) => getUser(bundle)?.email;

/**
 * Returns the user roles
 *
 * @param bundle
 * @returns {array}
 */
export const getUserRoles = (bundle) => getUser(bundle)?.roles;

/**
 * Returns the bare access token
 *
 * @param bundle
 * @returns {string | undefined}
 */
export const getBareAccessToken = (bundle) => bundle?.bare_access_token;

/**
 * Returns the access token
 *
 * @param bundle
 * @returns {string | undefined}
 */
export const getAccessToken = (bundle) => bundle?.access_token;

/**
 * Returns the refresh token
 *
 * @param bundle
 * @returns {string | undefined}
 */
export const getRefreshToken = (bundle) => bundle?.refresh_token;

/**
 * Computes the "local" bundle from a query parameter or the local storage,
 * in this priority.
 *
 * @returns {object|null|undefined}
 */
export const computeLocalBundle = () => {
  const urlParameter = new URLSearchParams(global?.location?.search);

  try {
    const urlParameterBundle = JSON.parse(urlParameter.get('bundle'));
    const localStorageBundle = JSON.parse(
      localStorage.getItem(BUNDLE_STORAGE_KEY)
    );
    let cookieBundle = Cookies.get('bundle');

    if (typeof cookieBundle !== 'undefined') {
      cookieBundle = JSON.parse(cookieBundle);
    }

    return urlParameterBundle || cookieBundle || localStorageBundle;
  } catch (error) {
    return null;
  }
};

/**
 * Updates the bundle to persistent locations.
 *
 * @param newBundle
 * @param {'login'|'refresh'=} mode
 */
export const updateBundle = (newBundle, mode = 'login') => {
  try {
    // Set bundle to local storage
    localStorage.setItem(BUNDLE_STORAGE_KEY, JSON.stringify(newBundle));

    // Set `bare_access_token` cookie for the asset API
    cookies.set('bare_access_token', getBareAccessToken(newBundle), {
      // Lifetime of 7 days equals the identity API token
      expires: 7,
    });

    if (mode === 'login') {
      // Identify user on Appsignal.
      appsignal.addDecorator((span) =>
        span.setTags({
          user_id: getUserId(newBundle),
        })
      );

      // Identify user on Analytics.
      window?.analytics?.identify(getUserId(newBundle));
    }

    appsignal.addBreadcrumb({
      action: 'Bundle updated',
      category: CATEGORIES.Console,
    });

    // eslint-disable-next-line no-empty
  } catch {}
};

/**
 * Returns the expiration state of the given token
 * by extracting the validation date and comparing it
 * with the current date including a leeway.
 *
 * @param token
 * @returns {Boolean}
 */
const isTokenExpired = (token) => {
  /* Leeway of 5 minutes. */
  const leewaySecs = 300;

  try {
    const rawPayload = (token || '').split('.')[1] || '';
    const payload = JSON.parse(atob(rawPayload));

    const issuedAt = (payload.iat || 0) - leewaySecs;
    const expiresAt = (payload.exp || 0) - leewaySecs;
    const now = new Date().getTime() / 1000;

    if (now >= issuedAt && now <= expiresAt) {
      /*
       * We're now between the point in time when the JWT was issued,
       * and the expiration date of the JWT
       */
      return false;
    }

    // Everything else is expired
    return true;
  } catch {
    // JSON parse errors on invalid JWTs
    return true;
  }
};

/**
 * Returns the expiration state of the access token.
 *
 * @param bundle
 * @returns {Boolean}
 */
export const isAccessTokenExpired = (bundle) =>
  isTokenExpired(getAccessToken(bundle));

/**
 * Returns the expiration state of the refresh token.
 *
 * @param bundle
 * @returns {Boolean}
 */
export const isRefreshTokenExpired = (bundle) =>
  isTokenExpired(getRefreshToken(bundle));
