export interface RevisionState {
  /**
   * Revision state of the MP
   * @description Is set to false if the revision file hash differs from the local revision hash.
   */
  isUpToDate: boolean;
  /**
   * Revision hash
   * @description Holds the revision hash, what is then compared on interval with the revision file.
   */
  hash: string | null;
}

/**
 * Prompt types
 */
export enum PromptType {
  offline = 'offline',
  update = 'update',
}

export interface PromptState {
  /**
   * Prompt types
   * @description Decides what type of prompt to show.
   */
  type: PromptType | undefined;
  /**
   * Show prompt
   * @description Decides if the prompt should be shown or not.
   */
  show: boolean;
}

/**
 * Reporter of the MP online status
 * @description Decides which reporter reported the app status.
 */
export enum Reporter {
  client = 'client',
  api = 'api',
}

export interface AppOnlineState {
  /**
   * App online status
   * @description Decides if the app is online or not.
   */
  isOnline: boolean;
  /**
   * Reporter of the MP online status
   * @description Decides which reporter reported the app status.
   */
  reporter: Reporter | null;
}
