// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#task-alarmreminder
const taskAlarm = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:task:alarm';

  stanzas.define({
    name: 'task_alarm',
    element: 'task_alarm',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      title: types.textSub('title'),
      description: types.textSub('description'),
      due_date: types.textSub('due_date'),
    },
  });
};

export default taskAlarm;
