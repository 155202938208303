/**
 * Custom runtimeCaching rules for next-pwa
 *
 * @description This file is used by the next-pwa plugin to generate the
 * service worker file. These rules define what to cache, where to store it and
 * how long the cache assets are valid.
 *
 * It is a modified copy of `node_modules/next-pwa/cache.js`. Then it's
 * injected into next-pwa config in next.config.js to override the default
 * runtimeCaching rules from `node_modules/next-pwa/cache.js`, See:
 * https://bit.ly/43OqVEB
 *
 * General Workbox RuntimeCaching config documentation:
 * https://bit.ly/43QE8g0
 *
 * New additions to the rules are marked with a comment like this: "Next-pwa
 * overwrite: reason why this is added"
 */

module.exports = [
  {
    urlPattern: /^https:\/\/fonts\.(?:gstatic)\.com\/.*/i,
    handler: 'CacheFirst',
    options: {
      cacheName: 'google-fonts-webfonts',
      expiration: {
        maxEntries: 4,
        maxAgeSeconds: 365 * 24 * 60 * 60, // 365 days
      },
    },
  },
  {
    urlPattern: /^https:\/\/fonts\.(?:googleapis)\.com\/.*/i,
    handler: 'StaleWhileRevalidate',
    options: {
      cacheName: 'google-fonts-stylesheets',
      expiration: {
        maxEntries: 4,
        maxAgeSeconds: 7 * 24 * 60 * 60, // 7 days
      },
    },
  },
  {
    urlPattern: /\.(?:eot|otf|ttc|ttf|woff|woff2|font.css)$/i,
    handler: 'StaleWhileRevalidate',
    options: {
      cacheName: 'static-font-assets',
      expiration: {
        maxEntries: 4,
        maxAgeSeconds: 7 * 24 * 60 * 60, // 7 days
      },
    },
  },
  {
    urlPattern: /\.(?:jpg|jpeg|gif|png|svg|ico|webp)$/i,
    handler: 'StaleWhileRevalidate',
    options: {
      cacheName: 'static-image-assets',
      expiration: {
        maxEntries: 64,
        maxAgeSeconds: 24 * 60 * 60, // 24 hours
      },
    },
  },
  {
    urlPattern: /\/_next\/image\?url=.+$/i,
    handler: 'StaleWhileRevalidate',
    options: {
      cacheName: 'next-image',
      expiration: {
        maxEntries: 64,
        maxAgeSeconds: 24 * 60 * 60, // 24 hours
      },
    },
  },
  {
    urlPattern: /\.(?:mp3|wav|ogg)$/i,
    handler: 'CacheFirst',
    options: {
      rangeRequests: true,
      cacheName: 'static-audio-assets',
      expiration: {
        maxEntries: 32,
        maxAgeSeconds: 24 * 60 * 60, // 24 hours
      },
    },
  },
  {
    urlPattern: /\.(?:mp4)$/i,
    handler: 'CacheFirst',
    options: {
      rangeRequests: true,
      cacheName: 'static-video-assets',
      expiration: {
        maxEntries: 32,
        maxAgeSeconds: 24 * 60 * 60, // 24 hours
      },
    },
  },
  {
    urlPattern: /\.(?:js)$/i,
    handler: 'StaleWhileRevalidate',
    options: {
      cacheName: 'static-js-assets',
      expiration: {
        maxEntries: 32,
        maxAgeSeconds: 24 * 60 * 60, // 24 hours
      },
    },
  },
  {
    urlPattern: /\.(?:css|less)$/i,
    handler: 'StaleWhileRevalidate',
    options: {
      cacheName: 'static-style-assets',
      expiration: {
        maxEntries: 32,
        maxAgeSeconds: 24 * 60 * 60, // 24 hours
      },
    },
  },
  {
    urlPattern: /\/_next\/data\/.+\/.+\.json$/i,
    handler: 'StaleWhileRevalidate',
    options: {
      cacheName: 'next-data',
      expiration: {
        maxEntries: 32,
        maxAgeSeconds: 24 * 60 * 60, // 24 hours
      },
    },
  },
  {
    urlPattern: /\.(?:json|xml|csv)$/i,
    handler: 'NetworkFirst',
    options: {
      cacheName: 'static-data-assets',
      expiration: {
        maxEntries: 32,
        maxAgeSeconds: 24 * 60 * 60, // 24 hours
      },
    },
  },
  {
    urlPattern: ({ url }) => {
      /* eslint-disable-next-line no-restricted-globals */
      const isSameOrigin = self.origin === url.origin;
      if (!isSameOrigin) return false;
      const { pathname } = url;
      /*
       * Exclude `/api/auth/callback/*` to fix OAuth workflow in Safari without
       * impact other environment Above route is default for next-auth, you may
       * need to change it if your OAuth workflow has a different callback
       * route. See: https://bit.ly/3W9PIkP
       */
      if (pathname.startsWith('/api/auth/')) return false;
      if (pathname.startsWith('/api/')) return true;
      return false;
    },
    handler: 'NetworkFirst',
    method: 'GET',
    options: {
      cacheName: 'apis',
      expiration: {
        maxEntries: 16,
        maxAgeSeconds: 24 * 60 * 60, // 24 hours
      },
      // Fall back to cache if api does not response within 10 seconds
      networkTimeoutSeconds: 10,
    },
  },
  {
    urlPattern: ({ url }) => {
      /* eslint-disable-next-line no-restricted-globals */
      const isSameOrigin = self.origin === url.origin;
      if (!isSameOrigin) return false;
      const { pathname } = url;
      if (pathname.startsWith('/api/')) return false;
      /*
       * Next-pwa overwrite: Do not cache the MPNs revision file. The app is
       * checking this files every 30 seconds.
       */
      if (pathname.endsWith('/REVISION')) return false;
      return true;
    },
    handler: 'NetworkFirst',
    options: {
      cacheName: 'others',
      expiration: {
        maxEntries: 32,
        maxAgeSeconds: 24 * 60 * 60, // 24 hours
      },
      networkTimeoutSeconds: 10,
    },
  },
  {
    urlPattern: ({ url }) => {
      /* eslint-disable-next-line no-restricted-globals */
      const isSameOrigin = self.origin === url.origin;
      /*
       * Next-pwa overwrite: Do not cache cross-origin requests to
       * maklerportal-apis revision json. It's checked on a short interval to
       * reason about the online state of the app.
       */
      if (url.origin.includes('maklerportal-api')) return false;
      return !isSameOrigin;
    },
    handler: 'NetworkFirst',
    options: {
      cacheName: 'cross-origin',
      expiration: {
        maxEntries: 32,
        maxAgeSeconds: 60 * 60, // 1 hour
      },
      networkTimeoutSeconds: 10,
    },
  },
];
