import { config } from 'app-config';

/**
 * Get revision hash
 * @description Fetches the Maklerportals revision file and returns its revision hash. A changed revision hash indicates an application update on the server.
 */
export const getRevisionHashQuery = async () => {
  /*
   * Check using current `windows.location.origin` if we are on a PR URL,
   * because the canary host does not allow CORS requests from the PR URLs.
   */
  const currentOrigin = window.location.origin;
  const currentUrlParts = currentOrigin.split('.');
  const isPrUrl =
    !currentUrlParts[0].includes('maklerportal-next') &&
    currentOrigin.includes('canary');
  const revisionUrl = `${isPrUrl ? currentOrigin : config.url}/REVISION`;

  return fetch(revisionUrl, {
    method: 'get',
    headers: {
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
  }).then((_response) => {
    if (_response.status !== 200) {
      return '';
    }

    return _response.text().then((_text) => _text.trim());
  });
};
