import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '@hausgold/designsystem';
import { isBrowser } from '../isEnv';

/**
 * Computes the current display mode of the app,
 * It also detects changes of the display mode.
 *
 * @description This hook is used to detect the current display mode of the app.
 * It is usefully to find out if the user has installed the PWA on their device.
 * The detection is possible with the `window.matchMedia` API. To check if the
 * current window is in standalone mode. It has also some fallbacks for older
 * browsers and iOS. Furthermore, it detects if the app was launched as a Trusted Web Activity (TWA),
 * when installed from the Play Store. When the user switches to fullscreen mode,
 * it remembers the previous installation state in the session storage.
 */

const usePWADisplayMode = () => {
  /**
   * The possible display modes of the app.
   */
  const enum DisplayMode {
    browser = 'browser',
    standalone = 'standalone',
    fullscreen = 'fullscreen',
    /*
     * Trusted Web Activity is a special standalone mode
     * on Android when bundled as an app in the Play Store
     */
    twa = 'twa',
    windowDisplayOverlay = 'windowDisplayOverlay',
  }

  const mediaQueryStandalone = '(display-mode: standalone)';
  const mediaQueryFullscreen = '(display-mode: fullscreen)';
  const mediaQueryWindowControlsOverlay =
    '(display-mode: window-controls-overlay)';

  /*
   * We want to detect fullscreen or standalone
   * If fullscreen is not supported, it falls back to standalone.
   * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/display-mode
   * Also the app can be installed as PWA and in fullscreen mode at the same time.
   */

  const [isStandalone, isFullscreen, isWindowControlsOverlay] = useMediaQuery(
    [
      mediaQueryStandalone,
      mediaQueryFullscreen,
      mediaQueryWindowControlsOverlay,
    ],
    {
      ssr: false,
    }
  );

  /**
   * Detect the current display mode of the app with some fallbacks.
   */
  const displayMode = useMemo(
    () => {
      // Only execute client-side as window is not available server-side
      if (isBrowser) {
        /*
         * FIXME: When we releasing the PWA as a Trusted Web Activity (TWA)
         *        the detection of the TWA may need to be improved,
         *        as relying on the document.referrer is not supported on
         *        ChromeOS right now, see the following issue:
         *        https://github.com/GoogleChrome/android-browser-helper/issues/64
         *        Possible solution may be to add a hash to the start-url in a
         *        TWA manifest and check for it and save this information in the session storage:
         *        https://micahjon.com/2021/pwa-twa-detection/#:~:text=Detecting%20a%20TWA%20(Trusted%20Web%20Activity)
         */
        if (document.referrer.startsWith('android-app://')) {
          return DisplayMode.twa; // Android's Trusted Web Activity
        }

        // @ts-ignore - navigator.standalone is available on Apple's iOS Safari only, but TypeScript doesn't know about it, yet.
        if (navigator.standalone || isStandalone) {
          return DisplayMode.standalone; // IOS >= 11.3, Chrome, Edge, Opera
        }

        if (isFullscreen) {
          return DisplayMode.fullscreen;
        }

        if (isWindowControlsOverlay) {
          return DisplayMode.windowDisplayOverlay;
        }

        /*
         * Firefox for Android has not full support for display-mode: standalone
         * and it only supports the 'browser' value:
         * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/display-mode#browser_compatibility
         */
        return DisplayMode.browser; // IOS <= 11.3, Firefox for Android (or other browser)
      }

      return undefined; // We are on the server here, so the display mode is undefined
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFullscreen, isStandalone, isWindowControlsOverlay]
  );

  const [currentDisplayMode, setCurrentDisplayMode] = useState(displayMode);

  const PWA = 'isInstalledPWA'; // The key for the session storage

  const isDisplayInStandalone = currentDisplayMode === DisplayMode.standalone;
  const isDisplayInFullscreen = currentDisplayMode === DisplayMode.fullscreen;
  const isDisplayInTWA = currentDisplayMode === DisplayMode.twa;
  const isDisplayInBrowser = currentDisplayMode === DisplayMode.browser;
  const isDisplayInWindowControlsOverlay =
    currentDisplayMode === DisplayMode.windowDisplayOverlay;
  const hasSessionStorage =
    typeof window !== 'undefined' && 'sessionStorage' in window;

  /*
   * We can assume that the app is installed as PWA
   * when it is in standalone or TWA, or was switched in fullscreen mode when the session storage key was set
   * after it was installed.
   */
  const wasInPWAMode = useMemo(
    () =>
      (hasSessionStorage &&
        sessionStorage.getItem(PWA) === 'yes' &&
        isDisplayInFullscreen) ||
      isDisplayInStandalone ||
      isDisplayInTWA ||
      isDisplayInWindowControlsOverlay,
    [
      isDisplayInStandalone,
      isDisplayInTWA,
      isDisplayInFullscreen,
      isDisplayInWindowControlsOverlay,
      hasSessionStorage,
    ]
  );

  const [isInstalledPWA, setIsInstalledPWA] = useState(wasInPWAMode);

  const resetSessionStorage = useCallback(() => {
    if (hasSessionStorage) {
      sessionStorage.setItem('isInstalledPWA', 'no');
    }
  }, [hasSessionStorage]);

  const handleSessionStorageUpdate = useCallback(
    (wasPWAInstalledState: boolean) => {
      // Save app installation state to session storage
      if (wasPWAInstalledState) {
        // When installed as PWA, set the session storage value
        setIsInstalledPWA(true);
        if (hasSessionStorage) {
          sessionStorage.setItem(PWA, 'yes');
        }
      } else {
        // If the app is not installed, sets the session storage value to false
        setIsInstalledPWA(false);
        resetSessionStorage();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetSessionStorage, hasSessionStorage]
  );

  /*
   * Detect changes of the display mode
   *
   * This is important when the user installs the app or
   * uses the "Open in app/browser" feature to switch from PWA to browser and
   * vice versa. Also, the user may switch from installed PWA to fullscreen or browser to fullscreen, and vice versa.
   */
  const detectChangesOfDisplayMode = useCallback(
    () => {
      // Detect changes of the display mode from browser <> standalone
      window.matchMedia(mediaQueryStandalone).addEventListener(
        'change',
        (e) => {
          // Update the current display mode and session storage
          if (e.matches) {
            // Browser > standalone
            setCurrentDisplayMode(DisplayMode.standalone);
          } else if (wasInPWAMode) {
            // Standalone > browser
            handleSessionStorageUpdate(false);
            setCurrentDisplayMode(DisplayMode.browser);
          }
        },
        { once: true }
      );

      // Detect changes of the display mode from standalone <> fullscreen
      if (wasInPWAMode) {
        window.matchMedia(mediaQueryFullscreen).addEventListener(
          'change',
          (e) => {
            // Update the current display mode and session storage
            if (e.matches) {
              // Standalone > fullscreen
              setCurrentDisplayMode(DisplayMode.fullscreen);
              handleSessionStorageUpdate(wasInPWAMode);
            } else if (isDisplayInWindowControlsOverlay) {
              // Fullscreen > windowControlsOverlay
              setCurrentDisplayMode(DisplayMode.windowDisplayOverlay);
            } else {
              // Fullscreen > standalone
              setCurrentDisplayMode(DisplayMode.standalone);
            }
          },
          { once: true }
        );
      } else {
        // Detect changes of the display mode from browser <> fullscreen
        window.matchMedia(mediaQueryFullscreen).addEventListener(
          'change',
          (e) => {
            // Update the current display mode and session storage
            if (e.matches) {
              // Browser > fullscreen
              setCurrentDisplayMode(DisplayMode.fullscreen);
              handleSessionStorageUpdate(wasInPWAMode);
            } else {
              // Fullscreen > browser
              setCurrentDisplayMode(DisplayMode.browser);
            }
          },
          { once: true }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isInstalledPWA,
      handleSessionStorageUpdate,
      mediaQueryStandalone,
      mediaQueryFullscreen,
    ]
  );

  /**
   * Detect the current display mode of the app on mount and sets it.
   * Handles storing of installed display mode to session storage.
   * Handles display mode changes.
   */
  useEffect(() => {
    setCurrentDisplayMode(displayMode);
    handleSessionStorageUpdate(wasInPWAMode);
    detectChangesOfDisplayMode();
  }, [
    wasInPWAMode,
    displayMode,
    detectChangesOfDisplayMode,
    handleSessionStorageUpdate,
  ]);

  const isPWA = useMemo(
    () =>
      (isInstalledPWA && !isDisplayInBrowser) ||
      isDisplayInStandalone ||
      isDisplayInTWA,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInstalledPWA, displayMode]
  );

  return {
    isPWA,
    isDisplayInStandalone,
    isDisplayInTWA,
    isDisplayInBrowser,
    isDisplayInFullscreen,
    isWindowControlsOverlay,
    currentDisplayMode,
  };
};

export default usePWADisplayMode;
