import {
  Box,
  Button,
  Collapse,
  HStack,
  Text,
  useTheme,
} from '@hausgold/designsystem';
import { useRouter } from 'next/router';
import { getRevisionHashQuery } from 'app-services/fetch/revisionApi';
import { useCallback, useEffect, useState } from 'react';
import { config, isRemote } from 'app-config';
import useInterval from 'app-utils/hooks/useInterval';
import appsignal from 'app-lib/appsignal';
import { sanitizeErrorMessage } from 'app-utils/appsignal/sanitizeErrorMessage';
import { useTranslation } from 'react-i18next';
import { getApiDeployment } from 'app-services/fetch/onlineCheckApi';
import { FORCE_UPDATE_STORAGE_KEY } from 'app-utils/constants/storageKeys';
import Head from 'next/head';
import {
  Reporter,
  PromptType,
  PromptState,
  RevisionState,
  AppOnlineState,
} from './StatusPrompt.types';

/**
 * Status Prompt
 * @description The prompt is shown above the page, currently if one of the following events occurs:
 * - Update: the MP got a new version deployed on the server and the user needs to hard reload to get the latest version.
 * - Offline: the app (client) goes offline and requests are not send out anymore, or the api is offline.
 */
const StatusPrompt = () => {
  const { t } = useTranslation();
  // We need the router to hard refresh the page
  const router = useRouter();

  const {
    colors: { red },
  } = useTheme();

  /**
   * Prompt spacing
   */
  const spacing = 4;

  /**
   * Prompt state
   * Decides what type and when to prompt
   */
  const [prompt, setPrompt] = useState<PromptState>({
    type: undefined,
    show: false,
  });

  /**
   * MP connection state
   * @description Holds the current MP connection state, which is determined by the clients network status and positive ping of the api.
   */
  const [appStatus, setAppStatus] = useState<AppOnlineState>({
    isOnline: true,
    reporter: Reporter.client,
  });

  /**
   * Check interval in ms for api ping
   */
  const CHECK_INTERVAL_API_PING = 15000;

  /**
   * Retries for error checks
   * @description Counts the retries for the error check, before sending an error to appsignal.
   */
  const [retries, setRetries] = useState<number>(0);

  /**
   * Max retries before sending error to appsignal
   */
  const MAX_RETRIES = 10;

  /**
   * Allow/disables the error reporting
   */
  const [allowErrorReporting, setAllowErrorReporting] = useState<boolean>(true);

  /**
   * Client based status check
   * @description Adds window event listeners to check if the client has network connection and immediately reports online status change.
   */
  useEffect(() => {
    const handleIsOnline = () => {
      setAppStatus({ isOnline: true, reporter: Reporter.client });
    };

    const handleIsOffline = () => {
      setAppStatus({ isOnline: false, reporter: Reporter.client });
    };

    if (
      typeof window !== 'undefined' &&
      'ononline' in window &&
      'onoffline' in window
    ) {
      if (!window.ononline) {
        window.addEventListener('online', handleIsOnline);
      }

      if (!window.onoffline) {
        window.addEventListener('offline', handleIsOffline);
      }
    }

    return () => {
      window.removeEventListener('online', handleIsOnline);
      window.removeEventListener('offline', handleIsOffline);
    };
  }, [appStatus]);

  /**
   * Api based status check
   * @description Because there could also be more reasons for a request to fail -- like firewall blocks -- we also ping an api on interval, to determine if the MP is online.
   */
  useInterval(async () => {
    getApiDeployment()
      .then((result) => {
        const { response, json } = result;

        // The API is online
        if (response?.status === 200) {
          setAppStatus({ isOnline: true, reporter: Reporter.api });
        }

        // The API is offline with reason
        if (json.error === 'MAINTENANCE_MODE') {
          throw new Error(json.reason.toLowerCase());
        }

        return null;
      })
      .catch((error) => {
        // Report api is offline
        setAppStatus({ isOnline: false, reporter: Reporter.api });
        // Send error to appsignal after x retries
        if (allowErrorReporting && retries >= MAX_RETRIES) {
          appsignal.send(
            appsignal.createSpan((span) => {
              if (error instanceof Error) {
                span.setError(sanitizeErrorMessage(error));
              }

              span.setAction('Api online check');
            })
          );
          // Disable error reporting, after error was sent to appsignal
          setAllowErrorReporting(false);
        }

        // Only send error to appsignal after x retries
        setRetries(retries + 1);
      });
  }, CHECK_INTERVAL_API_PING);

  /**
   * Revision state
   * @description Holds the latest fetched revision, that is compared on interval with deployments revision file. If this differs, the app is out-off-date and the status prompt will be shown.
   */
  const [revision, setRevision] = useState<RevisionState>({
    isUpToDate: true,
    // Set initial revision hash from bundle
    hash: config.deployment.commit,
  });

  /**
   * Check interval in ms for revision file
   */
  const CHECK_INTERVAL_REVISION = 30000;

  /**
   * Revision Check
   * @description Fetches revision hash string from revision file, sets initial local state, which is then compared on interval with the revision file.
   */
  const revisionCheck = useCallback(async () => {
    try {
      // Overwrite revision check with out-of-date flag from localstorage for QA-Testing
      if (localStorage.getItem(FORCE_UPDATE_STORAGE_KEY)) {
        // Set local revision is out of date and show update prompt
        setRevision({ isUpToDate: false, hash: 'QA_REVISION_OVERWRITE' });

        return null;
      }

      // Get revision hash from file
      const lastestRevision = await getRevisionHashQuery();

      if (lastestRevision === '') {
        throw new Error(
          `Revision file was empty or not found after ${retries} retries`
        );
      }

      // Local revision differs from the revision file, set local revision is out of date
      if (
        lastestRevision !== revision.hash ||
        localStorage.getItem(FORCE_UPDATE_STORAGE_KEY)
      ) {
        // Set local revision is out of date and show update prompt
        setRevision({ ...revision, isUpToDate: false });

        return null;
      }

      return null;
    } catch (error) {
      if (allowErrorReporting && retries >= MAX_RETRIES) {
        appsignal.send(
          appsignal.createSpan((span) => {
            if (error instanceof Error) {
              span.setError(sanitizeErrorMessage(error));
            }

            span.setAction('Revision check');
            span.setTags({ prev_revision: revision.hash ?? '' });
          })
        );
        // Disable error reporting, after error was sent to appsignal
        setAllowErrorReporting(false);
      }

      // Only send error to appsignal after x retries
      setRetries(retries + 1);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revision, retries, getRevisionHashQuery, allowErrorReporting]);

  useEffect(() => {
    /**
     * Start initial revision check
     * This is only triggered when the client is online and not in a local
     * environment.
     */
    if (
      (appStatus.isOnline && isRemote) ||
      localStorage.getItem(FORCE_UPDATE_STORAGE_KEY)
    ) {
      revisionCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Continues revision check
   * @description Checks the revision file on interval for changes.
   * Is only triggered when the client is online and not in a local
   * environment.
   */
  useInterval(async () => {
    if (
      (appStatus.isOnline && isRemote) ||
      localStorage.getItem(FORCE_UPDATE_STORAGE_KEY)
    ) {
      await revisionCheck();
    }
  }, CHECK_INTERVAL_REVISION);

  useEffect(() => {
    // Hide prompt when online or up-to-date
    if (appStatus.isOnline || revision.isUpToDate) {
      setPrompt({ type: undefined, show: false });
    }

    // Show offline prompt
    if (!appStatus.isOnline) {
      setPrompt({
        type: PromptType.offline,
        show: true,
      });
    }

    // Show update prompt
    if (appStatus.isOnline && !revision.isUpToDate) {
      setPrompt({
        type: PromptType.update,
        show: true,
      });
    }
  }, [appStatus, revision]);

  /**
   * Handle hard reload
   * @description Removes the out-of-date flag from localstorage, enqueue and update for all service workers and reloads the page.
   */
  const handleReload = () => {
    localStorage.removeItem(FORCE_UPDATE_STORAGE_KEY);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.update());
      });
    }

    router.reload();
  };

  return (
    <>
      {prompt.show && (
        <Head>
          <meta key="theme-color" name="theme-color" content={red['500']} />
        </Head>
      )}
      <Collapse in={prompt.show}>
        <Box
          display="flex"
          justifyContent="space-around"
          w="full"
          bg="red.500"
          p={spacing}
          color="white"
          fontSize="xl"
          position="relative"
        >
          <HStack spacing={spacing}>
            {prompt.type === PromptType.update && (
              <>
                <Text my="auto">{t('statusPrompt.newAppVersion.message')}</Text>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={() => handleReload()}
                >
                  {t('statusPrompt.newAppVersion.button')}
                </Button>
              </>
            )}
            {prompt.type === PromptType.offline && (
              <Text my="auto">{t('statusPrompt.offline.message')}</Text>
            )}
          </HStack>
        </Box>
      </Collapse>
    </>
  );
};

export default StatusPrompt;
