import { LogoMaklerportal, Spinner, VStack } from '@hausgold/designsystem';
import React, { useEffect, useState } from 'react';
import {
  computeLocalBundle,
  getRefreshToken,
  isAccessTokenExpired,
  isRefreshTokenExpired,
  updateBundle,
} from 'app-services/models/rest/bundle';
import { useRouter } from 'next/router';
import { config } from 'app-config';
import { tokenExpireTime } from 'app-services/api/identityApi';
import PWAPrompt from 'app-components/PWAPrompt';
import Head from 'next/head';
import StatusPrompt from 'app-components/StatusPrompt/StatusPrompt';
import appsignal, { CATEGORIES } from 'app-lib/appsignal';
import { REDIRECT_PATH_STORAGE_KEY } from 'app-utils/constants/storageKeys';
import routes from 'app-utils/constants/routes';

export type BundleCheckProviderProps = {
  children: React.ReactNode;
};

/**
 * Shows a loading page and manage the initial handling of a maybe given bundle.
 *
 * From user side this should prevent content flickering, where users may see old data or pages they do not have access to
 * because of an expired auth session.
 *
 * From technical side the initial bundle check should prevent some errors which may occur otherwise
 * as using a deprecated access token for queries or websocket connections can be prevented this way.
 * Also, in case the refresh token is still valid, we renew the access token here initially,
 * so all initial bundle handling is centralized here.
 */
const BundleCheckProvider = ({ children }: BundleCheckProviderProps) => {
  const router = useRouter();
  const [isBundleChecked, setIsBundleChecked] = useState(false);

  // Save current (initial called) pathname + search for proper redirect after login if no bundle exists or bundle is invalid.
  const saveLocation = () => {
    localStorage.setItem(
      REDIRECT_PATH_STORAGE_KEY,
      `${window.location?.pathname || ''}${window.location?.search || ''}`
    );
  };

  /*
   * Check the bundle for validation. Cases are:
   * 1. no bundle given
   *  I. but page require one -> redirect to login
   *  II. show the requested page
   * 2. bundle with valid access token
   *  I. but page require no one -> redirect to root page
   *  II. show the requested page
   * 3. bundle with expired access token but valid refresh token -> try refresh
   *  I. but page require no one -> redirect to root page
   *  II. log out
   *  III. show the requested page
   * 4. bundle with expired access token and expired refresh token -> logout
   */
  const checkAuth = async () => {
    appsignal.addBreadcrumb({
      action: 'Initialize app',
      category: CATEGORIES.Console,
    });

    const requiredRouteState = routes[router.route];
    const bundle = computeLocalBundle();

    // 1. If no jwt bundle exists, we are done.
    if (!bundle) {
      appsignal.addBreadcrumb({
        action: 'No bundle found',
        category: CATEGORIES.Console,
      });

      // I. If the page requires a bundle we redirect to login page.
      if (requiredRouteState === 'authorized') {
        saveLocation();
        await router.push('/login');
      }

      setIsBundleChecked(true);
    } else {
      const isExpiredAccessToken = isAccessTokenExpired(bundle);
      const isExpiredRefreshToken = isRefreshTokenExpired(bundle);
      const refreshToken = getRefreshToken(bundle);

      // 2. If bundle exists and access token is valid, we are done.
      if (!isExpiredAccessToken) {
        appsignal.addBreadcrumb({
          action: 'Valid bundle found',
          category: CATEGORIES.Console,
        });

        updateBundle(bundle);
        // I. If the page requires no bundle we redirect to root page.
        if (requiredRouteState === 'unauthorized') {
          await router.push('/');
        }

        setIsBundleChecked(true);
      }

      // 3. If access token is expired, but we have a valid refresh token -> refresh the bundle or logout if failed.
      else if (isExpiredAccessToken && refreshToken && !isExpiredRefreshToken) {
        appsignal.addBreadcrumb({
          action:
            'Bundle with expired access token but valid refresh token found',
          category: CATEGORIES.Console,
        });

        // Request new bundle (with new tokens).
        const response = await fetch(
          `${config.endpoints.identityApi}/v1/jwt/refresh`,
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refresh_token: refreshToken,
              expires_in: tokenExpireTime,
            }),
          }
        ).then((_response) => _response.json());

        // Handle error cases.
        if (response.code || !response?.access_token) {
          appsignal.addBreadcrumb({
            action: 'Bundle refresh failed',
            category: CATEGORIES.Console,
          });

          saveLocation();
          // II. Logout if token could not be refreshed.
          await router.push('/logout');
        } else {
          appsignal.addBreadcrumb({
            action: 'Bundle refreshed',
            category: CATEGORIES.Console,
          });

          updateBundle(response, 'refresh');
          // I. If the page requires no bundle we redirect to root page.
          if (requiredRouteState === 'unauthorized') {
            await router.push('/');
          }
        }

        setIsBundleChecked(true);
      }

      // 4. If both tokens are expired redirect to logout page.
      else if (isExpiredAccessToken && isExpiredRefreshToken) {
        appsignal.addBreadcrumb({
          action: 'Expired bundle found',
          category: CATEGORIES.Console,
        });

        saveLocation();
        await router.push('/logout');
        setIsBundleChecked(true);
      }
    }
  };

  // Runs the bundle checker once.
  useEffect(() => {
    // Ensures we get not stuck here for any obscure reason.
    let fallbackTimer: NodeJS.Timeout | undefined;

    if (!isBundleChecked) {
      checkAuth();

      fallbackTimer = setTimeout(() => {
        setIsBundleChecked(true);
      }, 3000);
    }

    return () => {
      if (fallbackTimer) {
        clearTimeout(fallbackTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBundleChecked]);

  // As long as we wait for bundle to be checked, we show an Intro-page and render some critical components only.
  if (!isBundleChecked) {
    return (
      <>
        <Head>
          <meta key="theme-color" name="theme-color" content="#ffffff" />
        </Head>
        {/*
         * Shows outdated app as early as possible.
         */}
        <StatusPrompt />
        {/*
         * In this state it will never show a UI.
         * However, it needs to block the system prompt right away which is done in an useEffect.
         */}
        <PWAPrompt isActive={false} />

        <VStack justify="center" bg="white" height="100vH">
          <LogoMaklerportal maxWidth="400px" maxHeight="100px" />
          <Spinner data-testid="initialSpinner" />
        </VStack>
      </>
    );
  }

  return children;
};

export default BundleCheckProvider;
