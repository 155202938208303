module.exports = {
  env: {
    node: true,
    browser: true,
  },
  parser: '@babel/eslint-parser',
  plugins: ['import'],
  overrides: [
    {
      // Lint those file extensions by default.
      files: ['*.jsx', '*.js', '*.ts', '*.tsx'],
    },
  ],
  rules: {
    /**
     * Force single quotes.
     * http://eslint.org/docs/rules/quotes
     */
    quotes: ['error', 'single'],

    /*
     * Disable camelcase rule for properties and destructuring because unfortunately, our REST APIs
     * do use camelcase.
     * https://eslint.org/docs/rules/camelcase
     */
    camelcase: ['error', { properties: 'never', ignoreDestructuring: true }],

    /*
     * Require or disallow trailing commas
     * https://eslint.org/docs/rules/comma-dangle
     */
    'comma-dangle': ['error', 'always-multiline'],

    /*
     * Require or disallow padding lines between statements
     * https://eslint.org/docs/rules/padding-line-between-statements
     */
    'padding-line-between-statements': [
      'error',
      {
        blankLine: 'always',
        prev: 'multiline-block-like',
        next: '*',
      },
    ],

    /**
     * Enforce multiline comment style
     * https://eslint.org/docs/rules/multiline-comment-style
     */
    'multiline-comment-style': ['warn', 'starred-block'],

    /*
     * Enforce capitalization of the first letter of a comment
     * https://eslint.org/docs/rules/capitalized-comments
     */
    'capitalized-comments': [
      'warn',
      'always',
      {
        ignoreInlineComments: true,
        ignoreConsecutiveComments: true,
        ignorePattern: 'prettier-ignore',
      },
    ],

    /*
     * Enforce spaced comments
     * https://eslint.org/docs/rules/spaced-comment
     */
    'spaced-comment': ['warn', 'always'],

    /**
     * Disable `no-underscore-dangle`.
     * https://eslint.org/docs/latest/rules/no-underscore-dangle
     */
    'no-underscore-dangle': ['off'],

    /**
     * Do not prefer default exports.
     * https://github.com/import-js/eslint-plugin-import/blob/main/docs/rules/prefer-default-export.md
     */
    'import/prefer-default-export': 'off',
  },
};
