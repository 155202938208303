// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-was-connected-to-immobilienscout24
const leadIs24Connected = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:is24_connected';

  stanzas.define({
    name: 'lead_is24_connected',
    element: 'lead_is24_connected',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      is24_id: types.attribute('is24_id'),
      owner: types.textSub('owner'),
      created_at: types.textSub('created_at'),
    },
  });
};

export default leadIs24Connected;
