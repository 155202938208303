import { ErrorBoundary as AppsignalErrorBoundary } from '@appsignal/react';
import FiveHundred from '../pages/500.page';

/**
 * ErrorBoundary
 *
 * Extends AppSignal's ErrorBoundary to include a reset function.
 */
class ErrorBoundary extends AppsignalErrorBoundary {
  resetErrorBoundary = () => {
    this.setState({ error: null });
  };

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <FiveHundred resetErrorBoundary={this.resetErrorBoundary} />;
    }

    return children;
  }
}

export default ErrorBoundary;
