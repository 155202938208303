// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-status-update-lead-state_updated
const leadStateUpdated = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:state_updated';

  const field = stanzas.define({
    name: 'field',
    element: 'field',
    namespace: NS,
    fields: {
      name: types.attribute('name'),
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'lead_state_updated',
    element: 'lead_state_updated',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      owner: types.textSub('owner'),
      changed_fields: types.subMultiExtension(NS, 'changed_fields', field),
      created_at: types.textSub('created_at'),
    },
  });
};

export default leadStateUpdated;
