const nodeEnv = process.env.NODE_ENV || 'development';
let appEnv = process.env.APP_ENV || 'development';

/*
 * Decides which ecosystem environment configuration the app is using.
 * This only changes the used ecosystem (HAUSGOLD API endpoints, etc), not the
 * tracking or third party services.
 */
let ecosystemEnv = process.env.API_ENV || appEnv;

// When we're running tests we do it with the canary environment
if (appEnv === 'test') {
  appEnv = 'canary';
  ecosystemEnv = appEnv;
}

const isBuild = (process.env.BUILD || 'false') === 'true';
const isRemote = nodeEnv === 'production';
const isLocal = !isRemote;

/* eslint-disable import/no-dynamic-require,global-require,import/extensions */
const config = {
  ...require(`./${appEnv}.js`),
  ...require('./ecosystem.js')[ecosystemEnv],
};
/* eslint-enable import/no-dynamic-require,global-require,import/extensions */

module.exports = {
  env: appEnv,
  ecosystemEnv,
  config,
  isBuild,
  isRemote,
  isLocal,
};
