// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#user-confirms-email-address
const userConfirmed = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:user:confirmed';

  stanzas.define({
    name: 'user_confirmed',
    element: 'user_confirmed',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      created_at: types.textSub('created_at'),
    },
  });
};

export default userConfirmed;
