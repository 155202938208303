// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-was-accepted-lead-accepted
const leadAccepted = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:accepted';

  stanzas.define({
    name: 'viewing_appointment',
    element: 'viewing_appointment',
    namespace: NS,
    fields: {
      format: types.attribute('format'),
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'lead_accepted',
    element: 'lead_accepted',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      owner: types.textSub('owner'),
      created_at: types.textSub('created_at'),
      viewing_appointment: types.textSub('viewing_appointment'),
    },
  });
};

export default leadAccepted;
