module.exports = {
  development: {
    endpoints: {
      gateway: 'http://gateway.local',
      maklerportalApi: 'http://maklerportal-api.local',
      identityApi: 'http://identity-api.local',
      dossiersProxy: 'http://dossiers-proxy.local',
    },
    jabber: {
      userHost: 'jabber.local',
      mucHost: 'conference.jabber.local',
      wsURL: 'ws://jabber.local/ws',
    },
  },
  canary: {
    endpoints: {
      gateway: 'https://gateway.canary.hausgold.de',
      maklerportalApi: 'https://maklerportal-api.canary.hausgold.de',
      identityApi: 'https://identity-api.canary.hausgold.de',
      dossiersProxy: 'https://dossiers-proxy.canary.hausgold.de',
    },
    jabber: {
      userHost: 'jabber.canary.hausgold.de',
      mucHost: 'conference.jabber.canary.hausgold.de',
      wsURL: 'wss://jabber.canary.hausgold.de/ws',
    },
  },
  production: {
    endpoints: {
      gateway: 'https://gateway.hausgold.de',
      maklerportalApi: 'https://maklerportal-api.hausgold.de',
      identityApi: 'https://identity-api.hausgold.de',
      dossiersProxy: 'https://dossiers-proxy.hausgold.de',
    },
    jabber: {
      userHost: 'jabber.hausgold.de',
      mucHost: 'conference.jabber.hausgold.de',
      wsURL: 'wss://jabber.hausgold.de/ws',
    },
  },
};
