// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#customer-was-not-reachable-by-phone
const userUnreachableByPhone = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:user:unreachable_by_phone';

  stanzas.define({
    name: 'user_unreachable_by_phone',
    element: 'user_unreachable_by_phone',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      created_at: types.textSub('created_at'),
    },
  });
};

export default userUnreachableByPhone;
