// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-supervisor-assignee-changed
const leadSupervisorChanged = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:supervisor_changed';

  stanzas.define({
    name: 'lead_supervisor_changed',
    element: 'lead_supervisor_changed',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      owner: types.textSub('owner'),
      supervisor: types.textSub('supervisor'),
    },
  });
};

export default leadSupervisorChanged;
