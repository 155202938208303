// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-was-created-lead-added-created
const leadAdded = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:added';

  stanzas.define({
    name: 'lead_added',
    element: 'lead_added',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      receiver: types.textSub('receiver'),
      created_at: types.textSub('created_at'),
    },
  });
};

const translation = () => {};

export default leadAdded;
export { translation };
