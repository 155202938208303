// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-evaluation-date-reminder
const leadEvaluationDateReminder = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:evaluation_date_reminder';

  stanzas.define({
    name: 'evaluation_date',
    element: 'evaluation_date',
    namespace: NS,
    fields: {
      format: types.attribute('format'),
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'lead_evaluation_date_reminder',
    element: 'lead_evaluation_date_reminder',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      task: types.attribute('task'),
      owner: types.textSub('owner'),
      evaluation_date: types.textSub('evaluation_date'),
    },
  });
};

export default leadEvaluationDateReminder;
