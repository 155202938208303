import PropTypes from 'prop-types';
import { RobotCircled } from '@hausgold/designsystem/illustrations';
import {
  Button,
  Container,
  HeadingH2,
  Link,
  Text,
} from '@hausgold/designsystem';
import { useTranslation } from 'react-i18next';
import { MinimalLayout } from 'app-components/Layouts';

const FiveHundred = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <MinimalLayout>
      <Container centerContent>
        <RobotCircled boxSize={{ base: 135, sm: 200 }} />
        <HeadingH2 as="h1" textAlign="center">
          {t('500.title')}
        </HeadingH2>
        <Text textAlign="center">
          {t('500.error')}
          <br />
          <Link
            title="Kontakt"
            href="https://www.hausgold.de/kontakt/"
            target="_blank"
          >
            {t('500.contactLink')}
          </Link>
          , {t('500.text')}
        </Text>
        <Text textAlign="center" />
        <Text textAlign="center">
          <Button mx="auto" onClick={() => resetErrorBoundary?.()}>
            {t('500.resetButton')}
          </Button>
        </Text>
      </Container>
    </MinimalLayout>
  );
};

FiveHundred.propTypes = {
  resetErrorBoundary: PropTypes.func,
};

FiveHundred.defaultProps = {
  resetErrorBoundary: null,
};

export default FiveHundred;
