import { Box } from '@hausgold/designsystem';

/**
 * Main
 *
 * Displays the main content element.
 *
 * @param {import('@hausgold/designsystem').BoxProps} props
 */
const Main = (props) => (
  <Box
    as="main"
    role="main"
    className="content"
    __css={{ w: '100%' }}
    {...props}
  />
);

Main.propTypes = {};

export default Main;
