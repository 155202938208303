// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-status-update-verz%C3%B6gerung-aufgehoben-lead-state_update_unskipped
const leadStateUpdateUnskipped = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:state_update_unskipped';

  stanzas.define({
    name: 'lead_state_update_unskipped',
    element: 'lead_state_update_unskipped',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      owner: types.textSub('owner'),
      created_at: types.textSub('created_at'),
    },
  });
};

export default leadStateUpdateUnskipped;
