import i18n from 'app-utils/i18n';
import { format, isDate } from 'date-fns';
import { ID } from 'app-graphql';
import { camelCase, isObject } from 'lodash-es';
import {
  MessageFormatted,
  MessageWithStanza,
  RawMessage,
  RawMessageOfMamItem,
  RawMessageOfSubscription,
} from 'app-components/jabber/messages';

// Fix IDs of our own "users".
const BOT_ID = '1f258b6f-2455-4be2-b065-266572785f7c';
const AGENT_ID = 'e4d83b80-eb96-453e-9586-80b952c468f4';

// Creates the jabber JID from user id which is the GID of jabber.
export const userIdToJid = (id: ID, userHost: string) => `${id}@${userHost}`;

// Creates the jabber JID from lead id which is the GID of jabber.
export const leadIdToJid = (id: ID, mucHost: string) => `lead-${id}@${mucHost}`;

// Creates the jabber JID from broker id which is the GID of jabber.
export const brokerIdToJid = (id: ID, mucHost: string) =>
  `broker-notifications-${id}@${mucHost}`;

// Checks if a given string is an ISO date
const isISODateAsString = (string: string = '') => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(string)) return false;

  return new Date(string).toISOString() === string;
};

// These are the keys a message can have that are NOT stanzas.
const NOT_STANZA_KEYS = [
  'from',
  'to',
  'muc',
  'type',
  'processingHints',
  'delay',
  '$body',
  'body',
  'id',
  'lang',
  'addresses',
  'stanzaId',
];

// List of internal state properties which should not be propagated to the user.
const REJECTED_STATE_FIELDS = [
  // Phases
  'acquisition_completed',
  'marketing_completed',
  'sale_completed',
  /*
   * Hidden fields which are not shown on tasks steps (anymore):
   * valuation
   */
  'customer_appointment_date',
  'currency',
  'perform_evaluation_date_took_place',
  'next_customer_contact_date',
  // Sign marketing
  'broker_contract_buyer_commission_unit',
  'broker_contract_type',
  'expected_sale_price',
  'expected_sale_notary_date',
  // Notary date
  'object_sale_buyer_commission_unit',
  'object_sale_buyer_commission_taxation',
  // Invoice - nothing to ignore
];

// Get rich information about the creator of a message by given user resource.
const getWriterFromResource = (resource: string) => {
  const writerParts = resource?.split('(');
  const name = writerParts?.slice(0, -1)?.[0]?.trim();
  const id = writerParts?.slice(-1)?.[0]?.replace(')', '');
  const isBot = id === BOT_ID;
  const isAgent = id === AGENT_ID;

  return {
    name,
    id,
    isBot,
    isAgent,
  };
};

// Get all stanzas from a message object.
const getStanzasFromMessage = (message: RawMessage): {} | object => {
  const stanzaKeys = Object.keys(message).filter(
    (key) => !NOT_STANZA_KEYS.includes(key)
  );

  const stanzas = {};
  if (stanzaKeys.length) {
    stanzaKeys.forEach((key) => {
      // @ts-ignore No stanzas typed yet just to satisfy this type.
      stanzas[key] = message[key];
    });
  }

  return stanzas;
};

// Converts a stanza data object into a single, human-readable string based on our translation files.
export const getStanzaTranslation = (
  message: MessageFormatted
): MessageWithStanza => {
  // @ts-ignore No stanzas typed yet just to satisfy this type.
  const stanzaType = Object.keys(message?.stanzas)?.[0];
  if (!stanzaType) return message;

  // @ts-ignore No stanzas typed yet just to satisfy this type.
  const stanzaObject = message.stanzas[stanzaType];

  const dateTimeValue =
    stanzaObject?.viewing_appointment?.value ||
    stanzaObject?.due_date?.value ||
    stanzaObject?.until_date?.value;

  /*
   * Stanza for changed task properties is more complex as it echos all fields which had changed.
   * Also removes fields which are not shown to the user but set for backend.
   */
  const changedFields = stanzaObject?.changed_fields
    ?.filter(
      (field: { name: string; value: unknown }) =>
        !REJECTED_STATE_FIELDS.includes(field.name)
    )
    ?.map(({ value, name }: { value: unknown; name: string }) => {
      // Convert backends snake_case string into frontend's camelCase for matching up.
      let fieldName = camelCase(name);

      // Formats the value if it is a general one or pass it as it is otherwise.
      let translatedValue = value;
      if (value === undefined) {
        if (fieldName === 'shareEvaluation' || fieldName === 'invoiceSent') {
          translatedValue = i18n.t('common:binary_false');
        } else {
          translatedValue = i18n.t('common:empty');
        }
      } else if (value === 'true') {
        translatedValue = i18n.t('common:binary_true');
      } else if (value === 'false') {
        translatedValue = i18n.t('common:binary_false');
        // Both checks are only present to satisfy TS.
      } else if (value === null || isObject(value)) {
        translatedValue = undefined;
      } else if (isDate(value) || isISODateAsString(String(value))) {
        translatedValue = format(new Date(String(value)), 'dd.MM.yyyy, HH:mm');
      }

      // This field name differ from our translation keys, so we reassign it if it occurs.
      if (fieldName === 'evaluationGid') {
        fieldName = 'evaluationFile';
      }

      /*
       * Get translation name for given field name.
       * As the field labels are nested and separated to its tasks we need
       * to check if the translation exists first.
       * Otherwise, we would just echo the translation key.
       *
       * Note: Some translations are not usable e.g. because they contain styles.
       *  Therefore we catch some fields and provide an own translation for.
       */
      fieldName =
        (fieldName === 'updateAlternativeText' &&
          i18n.t('stanza:lead_state_updated.field.updateAlternativeText')) ||
        (fieldName === 'invoiceSent' &&
          i18n.t('stanza:lead_state_updated.field.invoiceSent')) ||
        (i18n.exists(`tasks:valuation.${fieldName}.label`, { ns: 'tasks' }) &&
          i18n.t(
            `tasks:valuation.${fieldName}.label`,
            'tasks:valuation.key missing',
            { ns: 'tasks' }
          )) ||
        (i18n.exists(`tasks:valuation.${fieldName}`) &&
          i18n.t(
            `tasks:valuation.${fieldName}`,
            'tasks:valuation.key missing',
            { ns: 'tasks' }
          )) ||
        (i18n.exists(`tasks:signMarketingContract.${fieldName}.label`) &&
          i18n.t(
            `tasks:signMarketingContract.${fieldName}.label`,
            'tasks:valuation.key missing',
            {
              ns: 'tasks',
            }
          )) ||
        (i18n.exists(`tasks:signMarketingContract.${fieldName}`) &&
          i18n.t(
            `tasks:signMarketingContract.${fieldName}`,
            'tasks:valuation.key missing',
            {
              ns: 'tasks',
            }
          )) ||
        (i18n.exists(`tasks:notaryDate.${fieldName}.label`) &&
          i18n.t(
            `tasks:notaryDate.${fieldName}.label`,
            'tasks:valuation.key missing',
            { ns: 'tasks' }
          )) ||
        (i18n.exists(`tasks:notaryDate.${fieldName}`) &&
          i18n.t(
            `tasks:notaryDate.${fieldName}`,
            'tasks:valuation.key missing',
            { ns: 'tasks' }
          )) ||
        (i18n.exists(`tasks:invoice.${fieldName}.label`) &&
          i18n.t(
            `tasks:invoice.${fieldName}.label`,
            'tasks:valuation.key missing',
            { ns: 'tasks' }
          )) ||
        (i18n.exists(`tasks:invoice.${fieldName}`) &&
          i18n.t(`tasks:invoice.${fieldName}`, 'tasks:valuation.key missing', {
            ns: 'tasks',
          })) ||
        fieldName;

      /*
       * Removes trailing ' *' as some fields are marked as required in its translation
       * as the tasks forms are not mandatory itself which would auto-add them.
       */
      fieldName = fieldName.replace(' *', '');

      // In case of a status update with alternative text from user, we format the message differently and return the translated value without parenthesis.
      return fieldName === 'Status'
        ? translatedValue
        : `${fieldName} (${translatedValue})`;
    })
    ?.join(', ');

  // Checks if this is a user status update with alternative text. Used to format the message differently
  const isUpdateAlternativeText =
    stanzaType === 'lead_state_updated' &&
    stanzaObject?.changed_fields[0]?.name === 'update_alternative_text';

  // We want sometimes a different format for the translated message body, dependent on the context.
  const computeContext = () => {
    if (dateTimeValue) return 'with_date';
    if (isUpdateAlternativeText) return 'update_alternative_text';
    return undefined;
  };

  const options = {
    context: computeContext(),
    receiverName: stanzaObject?.receiver?.value,
    ownerName: stanzaObject?.owner?.value,
    supervisorName: stanzaObject?.supervisor?.value,
    assigneeName: stanzaObject?.assignee?.value,
    closedComment: stanzaObject?.comment?.value,
    processedComment: stanzaObject?.comment?.value,
    skippedReason: stanzaObject?.reason?.value,
    date: dateTimeValue
      ? format(new Date(dateTimeValue), 'dd.MM.yyyy')
      : undefined,
    time: dateTimeValue ? format(new Date(dateTimeValue), 'HH:mm') : undefined,
    title: stanzaObject?.title?.value,
    description: stanzaObject?.description?.value,
    dueDate: isDate(stanzaObject?.due_date?.value)
      ? format(new Date(stanzaObject?.due_date?.value), 'dd.MM.yyyy, HH:mm')
      : undefined,
    changedFields,
    interpolation: {
      escapeValue: false,
    },
    ns: ['stanza'],
  };

  /*
   * Some stanza types have an optional date, like 'lead_accepted'.
   * For those the `context` ensures that the right translation is taken.
   * However, `context` needs a string by any means.
   * `Undefined`, `false`, `null` etc. will result in `null` as return value.
   * That's why we remove the entire key if it is unset.
   */
  if (options.context === undefined) {
    delete options.context;
  }

  // Ready-to-use translation for Logbook. As mentioned in `formatRawMessage()` only the "first" stanza is represented.
  const stanzaLogbookBody: string = i18n.t(
    `${stanzaType}.logbook`,
    options as unknown as string // FIXME: ts thinks this is the defaultValue and wants it to be a string. Why? This overload matches one of the TFunctions signatures.
  );

  // Ready-to-use translation for NotificationCenter. As mentioned in `formatRawMessage()` only the "first" stanza is represented.
  const stanzaNotificationHeader =
    stanzaType === 'lead_added' || stanzaType === 'lead_access_permitted'
      ? i18n.t(`stanza:${stanzaType}.notificationHeader`)
      : undefined;
  const stanzaNotificationBody =
    stanzaType === 'lead_added' || stanzaType === 'lead_access_permitted'
      ? i18n.t(`stanza:${stanzaType}.notificationBody`)
      : undefined;
  // Right now all notifications link to the lead details "root" page.
  const notificationLink = stanzaObject?.id
    ? `/lead/${stanzaObject.id}/property`
    : undefined;

  return {
    ...message,
    stanzaLogbookBody,
    stanzaNotificationHeader,
    stanzaNotificationBody,
    notificationLink,
  };
};

/*
 * Gets the nested message object from a raw message object.
 * The object differ in relation to its sender.
 */
const getMessageObjectFromRawMessage = (
  rawMessage: RawMessageOfMamItem | RawMessageOfSubscription
) => {
  const messageObject =
    // Messages from history received over "message" event instead of request result

    // @ts-ignore "Property 'mamItem' does not exist on type 'RawMessageOfSubscription'."
    rawMessage?.mamItem?.forwarded?.message ||
    // Used for (broker) chat messages

    // @ts-ignore "Property 'mamItem' does not exist on type 'RawMessageOfMamItem'."
    rawMessage?.forwarded?.message ||
    // Used for own messages send from a different device while both devices are connected.

    // @ts-ignore No data for but we keep it because it was used in old jabber as well.
    rawMessage?.carbonSent?.forwarded?.message ||
    // Fallback
    rawMessage;

  /*
   * Use `multiplexed` message if available.
   * Spreading a message into different rooms like for Notifications will use multiplexed.
   */
  const isMultiplexed = !!messageObject.multiplexed;
  if (isMultiplexed) {
    return messageObject.multiplexed.message;
  }

  return messageObject;
};

// Extract all important information from a given raw message object.
export const formatRawMessage = (
  rawMessage: RawMessageOfMamItem | RawMessageOfSubscription
): MessageFormatted => {
  const message = getMessageObjectFromRawMessage(rawMessage);

  let timestamp;
  // In case the data is crap we catch the error `.toISOString()` would raise.
  try {
    timestamp = new Date(
      // @ts-ignore "Property 'stanzaId' does not exist on type 'RawMessageOfSubject'." <-- RawMessageOfSubject will never be used here.
      (rawMessage.stanzaId || rawMessage.id) / 1000
    ).toISOString();
  } catch (e) {
    /* Empty */
  }

  const writer = getWriterFromResource(message.from.resource);
  const stanzas = getStanzasFromMessage(message);

  return {
    from: {
      bare: message.from.bare,
      resource: message.from.resource,
      isBot: writer.isBot,
      isAgent: writer.isAgent,
      name: writer.name,
      id: writer.id,
    },
    to: {
      bare: message.to.bare,
      resource: message.to.resource,
    },
    timestamp,
    chatBody: message.body,
    /*
     * In all seen cases there is only one stanza at once within and only the first one would be used code-wise by us.
     * However, just to be sure I keep the old structure from `react-jabber` to prevent future breaking changes here.
     */
    stanzas,
    // @ts-ignore "Property 'stanzaId' does not exist on type 'RawMessageOfSubject'." <-- Of course not, that is why the conditional is here...
    id: message?.id || rawMessage?.stanzaId || rawMessage.id,
  };
};
