// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-due-date-for-accepting-was-extended-lead-due_date_extended
const leadDueDateExtended = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:due_date_extended';

  stanzas.define({
    name: 'due_date',
    element: 'due_date',
    namespace: NS,
    fields: {
      format: types.attribute('format'),
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'lead_due_date_extended',
    element: 'lead_due_date_extended',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      created_at: types.textSub('created_at'),
      due_date: types.textSub('due_date'),
    },
  });
};

export default leadDueDateExtended;
