import { isArray } from 'lodash-es';

/**
 * Concatenates an array of strings with spaces or commas, avoiding pitfalls like extra spaces, undefined string conversions or
 * similar.
 * Separation defaults to spaces.
 *
 * @param {Array.<string | number | undefined | null>} strings
 * @param {'space' | 'comma'} options
 * @returns {string|undefined}
 */
export const concatenateStrings = (strings, options) => {
  if (!isArray(strings)) return undefined;

  let separation;
  switch (options) {
    case 'comma':
      separation = ', ';
      break;
    case 'space':
      separation = ' ';
      break;
    default:
      separation = ' ';
  }

  return (
    strings
      // Filter all falsy or non-number/non-string values
      .filter(
        (value) =>
          (typeof value === 'string' || typeof value === 'number') && !!value
      )
      // Convert everything to strings
      .map((value) => String(value))
      // Trim all values before concatenating, eliminating double spaces
      .map((value) => value.trim())
      // Add spaces
      .join(separation)
      // Trim again to cut off the last space
      .trim() || undefined
  );
};
