import { useDeleteNotificationTokenMutation } from 'app-graphql';
import { createNotificationTokenMutation } from 'app-services/fetch/identityApi';
import { config } from 'app-config';
import appsignal from 'app-lib/appsignal';
import { sanitizeErrorMessage } from 'app-utils/appsignal/sanitizeErrorMessage';
import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import Router from 'next/router';
import { useEffect, useState } from 'react';
import { useUserId } from 'app-services/queries/bundleQueries';
import useTracking from 'app-utils/hooks/useTracking';
import { eventBuilder, GROUP } from 'app-utils/constants/tracking';

// Amount of max retries to get the firebase token from firebase.
const MAX_RETRIES = 4;

/*
 * The global stored id which contains the fcmToken.
 */
let fcmTokenId;

/*
 * Setter function for the global stored id which contains the fcmToken.
 */
const setFcmTokenId = (newToken) => {
  fcmTokenId = newToken;
};

/**
 * This hook holds the backend id which contains the firebase cloud messaging (fcm) token which is used for getting push notifications.
 * The hook is needed because adding and removing this token happens on different places in the code (initialize and logout).
 */
export const useFcmTokenContext = () => [fcmTokenId, setFcmTokenId];

/**
 * Manage a Firebase instance used for incoming notifications.
 */
const useFirebase = () => {
  const userId = useUserId();
  const [, deleteNotificationToken] = useDeleteNotificationTokenMutation();
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const [fcmTokenId, setFcmTokenId] = useFcmTokenContext();
  const [isServiceWorkerReady, setIsServiceWorkerReady] = useState(false);
  const { segmentTrack } = useTracking();

  /*
   * Get token from firebase cloud messaging (fcm) and save it on backend.
   */
  const getAndRegisterFcmToken = (messaging) => {
    let retries = 0;

    // Function to send firebase token to backend, so notifications can be sent to this user/application.
    const registerNotificationToken = async (
      currentToken,
      isAfterDeletion = false
    ) => {
      const createNotificationTokenResponse =
        await createNotificationTokenMutation({
          userId,
          token: currentToken,
          appId: config.firebase.appId,
          projectId: config.firebase.projectId,
        });

      // Save the id of the backend entry which holds the fcm token, so it can be removed on logout easily.
      if (createNotificationTokenResponse?.id) {
        setFcmTokenId(createNotificationTokenResponse.id);
      }

      if (createNotificationTokenResponse?.message) {
        /*
         * In case the token is already used elsewhere we delete the old instance from backend and add a new one,
         * so the notifications are pushed to this device (and not to the previous one anymore).
         */
        if (
          createNotificationTokenResponse.message?.includes(
            'Duplicated notification token.'
          ) &&
          createNotificationTokenResponse?.reason &&
          !isAfterDeletion
        ) {
          const deleteTokenResponse = await deleteNotificationToken({
            id: createNotificationTokenResponse?.reason,
          });

          // If deletion was successful we register the new one.
          if (
            deleteTokenResponse?.data?.notificationToken?.delete
              ?.notificationToken
          ) {
            await registerNotificationToken(currentToken, true);
            // Else we track to AppSignal that deletion failed.
          } else {
            appsignal.send(
              appsignal.createSpan((span) => {
                span.setAction(
                  'Unregister token from firebase cloud messaging from backend failed'
                );
                span.setTags({
                  response: sanitizeErrorMessage(deleteTokenResponse?.error),
                  userId,
                  projectId: config.firebase.projectId,
                  appId: config.firebase.appId,
                  token: currentToken,
                  id: createNotificationTokenResponse?.reason,
                });
                span.setError(sanitizeErrorMessage(deleteTokenResponse?.error));
              })
            );
          }
          // In every other case we send a report to AppSignal.
        } else {
          appsignal.send(
            appsignal.createSpan((span) => {
              span.setAction(
                'Register token from firebase cloud messaging to backend failed'
              );
              span.setTags({
                response: sanitizeErrorMessage(
                  createNotificationTokenResponse?.error
                ),
                userId,
                projectId: config.firebase.projectId,
                appId: config.firebase.appId,
                token: currentToken,
              });
              span.setError(
                sanitizeErrorMessage(createNotificationTokenResponse?.error)
              );
            })
          );
        }
      }
    };

    /*
     * Request token from fcm.
     *
     * In case the fcm "push manager" part is not loaded yet, we will retry it a couple of times.
     */
    const getTokenFromFcm = () => {
      getToken(messaging)
        .then(async (currentToken) => {
          if (currentToken) {
            await registerNotificationToken(currentToken);
          }
        })
        .catch((error) => {
          /*
           * Stop retrying and don't send an error to AppSignal if no permission is given.
           */
          const abort = error.message?.includes(
            'User denied permission to use the Push API.'
          );

          /*
           * Retry getting token if it did not work.
           * Common issue is that after permission is granted the SW push scope instance (not our SW itself ) is not ready yet.
           */
          if (!abort && retries < MAX_RETRIES) {
            retries += 1;
            setTimeout(() => {
              getTokenFromFcm();
            }, 3000);
            // Send AppSignal report if the token could not be received after retries.
          } else {
            appsignal.send(
              appsignal.createSpan((span) => {
                span.setAction(
                  'Getting token from firebase cloud messaging failed'
                );
                span.setTags({
                  response: sanitizeErrorMessage(error),
                  userId,
                });
                span.setError(sanitizeErrorMessage(error));
              })
            );
          }
        });
    };

    getTokenFromFcm();
  };

  const firebaseApp = initializeApp(config.firebase);

  useEffect(() => {
    // Check if user/browser granted permission to send notifications.
    const allowsNotifications =
      ('Notification' in window && Notification.permission === 'granted') ||
      false;

    /*
     * Requests firebase token and send it to backend if
     * - initial auth/token check is done
     * - user is logged in (has a user id)
     * - the firebase token is not set already
     * - notifications are allowed by user/browser
     * - service worker is running
     */
    if (userId && !fcmTokenId && allowsNotifications && isServiceWorkerReady) {
      /*
       * Get registration token. Initially this makes a network call, once retrieved
       * subsequent calls to getToken will return from (firebase own) cache.
       */
      const messaging = getMessaging(firebaseApp);

      getAndRegisterFcmToken(messaging);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fcmTokenId, userId, isServiceWorkerReady]);

  /*
   * Reset firebase token if user logs out, so a new login can request a new firebase token.
   */
  useEffect(() => {
    if (!userId) {
      setFcmTokenId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  /*
   * Listen that the service worker is loaded and initialized.
   */
  useEffect(() => {
    if ('serviceWorker' in navigator && !isServiceWorkerReady) {
      navigator.serviceWorker.ready.then((result) => {
        if (result) {
          setIsServiceWorkerReady(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Handles messages from service worker which includes instructions to redirect to a specific page.
   */
  useEffect(() => {
    // Function to hande service worker messages to redirect to a given page.
    const handleMessages = (event) => {
      // Only allow messages from our trusted domain and with expected action.
      if (
        event.origin.endsWith(config.trustedDomain) &&
        event.data.action !== 'notification_clicked'
      ) {
        return;
      }

      if (event.data.action === 'notification_clicked') {
        // Track notification click event
        segmentTrack(eventBuilder('PushNotification', 'Interacted'), {
          group: GROUP.notification,
          leadIdentifier: `${event.data.leadId}`,
        });
      }

      // We only support "new lead" for now.
      if (event.data?.type === 'lead_added' && event.data?.leadId) {
        // Redirect
        Router.push(`/lead/${event.data.leadId}/property`);
      }
    };

    // Check if events can be added to serviceworker already (and if it makes sense). If not ready it raises errors in console otherwise.
    const canHandleEvents =
      isServiceWorkerReady &&
      userId &&
      'PushManager' in window &&
      'Notification' in window &&
      Notification.permission === 'granted';

    if (canHandleEvents) {
      // Register the event, so service worker events can call it.
      navigator.serviceWorker.addEventListener('message', handleMessages, true);
    }

    // Removes event to prevent memory leaks if one exists.
    return () => {
      if (canHandleEvents) {
        navigator.serviceWorker.removeEventListener(
          'message',
          handleMessages,
          true
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isServiceWorkerReady, userId]);
};

export default useFirebase;
