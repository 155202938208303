/*
 * In order to use the XMPP in practise we make the stanza-id available to
 * each message. This id is service-bound to ejabberd, but it is reliable.
 * The service generates this integer id at time of receiving in µs (UNIX
 * epoch). It can and should be used for unique message identitification,
 * sorting, etc. Afterwards each message contains: +message.messageId::int+
 */
const stanzaId = (client, stanzas) => {
  stanzas.withMessage((Message) => {
    stanzas.add(
      Message,
      'stanzaId',
      stanzas.utils.numberSubAttribute('urn:xmpp:sid:0', 'stanza-id', 'id')
    );
  });
};

export default stanzaId;
