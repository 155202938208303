module.exports = {
  extends: ['../../eslint-config/shared/react', require.resolve('./native')],
  rules: {
    /**
     * Do not require default props for TypeScript.
     * https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/require-default-props.md
     */
    'react/require-default-props': 'off',
    /**
     * Do not require prop types for TypeScript.
     * https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/prop-types.md
     */
    'react/prop-types': 'off',
  },
};
