// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-status-update-skipped-lead-state_update_skipped
const leadStateUpdateSkipped = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:state_update_skipped';

  stanzas.define({
    name: 'reason',
    element: 'reason',
    namespace: NS,
    fields: {
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'until_date',
    element: 'until_date',
    namespace: NS,
    fields: {
      format: types.attribute('format'),
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'lead_state_update_skipped',
    element: 'lead_state_update_skipped',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      owner: types.textSub('owner'),
      created_at: types.textSub('created_at'),
      until_date: types.textSub('until_date'),
      reason: types.textSub('reason'),
    },
  });
};

export default leadStateUpdateSkipped;
