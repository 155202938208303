/*
 * This stanza has multiple namespaces with varying suffixes, but the same properties. See
 * `NSSuffixes` below.
 */
const leadMandatoryTaskDone = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:mandatory_task';

  const NSSuffixes = [
    // https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-mandatory-task-done-plan_valuation_meeting
    'plan_valuation_meeting',
    // https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-mandatory-task-done-have_valuation_meeting
    'have_valuation_meeting',
    // https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-mandatory-task-done-send_valuation_report
    'send_valuation_report',
    // https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-mandatory-task-done-sign_marketing_contract
    'sign_marketing_contract',
    // https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-mandatory-task-done-plan_notary_date
    'plan_notary_date',
    // https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-mandatory-task-done-sign_contract_at_notary
    'sign_contract_at_notary',
    // https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-mandatory-task-done-send_contract_copy_to_us
    'send_contract_copy_to_us',
  ];

  NSSuffixes.forEach((suffix) => {
    stanzas.define({
      name: 'lead_mandatory_task_done',
      element: 'lead_mandatory_task_done',
      namespace: `${NS}:${suffix}`,
      fields: {
        id: types.attribute('id'),
        property_id: types.attribute('property_id'),
        supervisor: types.textSub('supervisor'),
        task: types.attribute('task'),
        owner: types.textSub('owner'),
        created_at: types.textSub('created_at'),
      },
    });
  });
};

export default leadMandatoryTaskDone;
