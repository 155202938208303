import { isLocal } from 'app-config';

export const REQUEST_ID_REGEX = /(Request: )\[([0-9a-f]{6})]/gi;
export const GRAPHQL_PREFIX_REGEX = /^\[GraphQL] /g;

/** String representing a GID prefix regex. */
const GID_PREFIX_REGEX = /gid:\/\/[a-z-]+\/[a-z-]+\//i;
/** Regex to match IDs. Important: Also matches IDs within GIDs. */
export const ID_REGEX =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
/** Regex to match IDs or GIDs. Used to find IDs-only by filtering out the result if it starts with the GID prefix. */
export const ID_OR_GID_REGEX = new RegExp(
  `(${GID_PREFIX_REGEX.source})?${ID_REGEX.source}`,
  `gi`
);
/** Regex to match GIDs. */
export const GID_REGEX = new RegExp(
  `${GID_PREFIX_REGEX.source}${ID_REGEX.source}`,
  `gi`
);

/**
 * Replace `Request: [123456]` with `Request: [***]`.
 * @param string
 */
export const replaceRequestIds = (string: string): string =>
  string.replaceAll(
    REQUEST_ID_REGEX,
    (match, prefix) => `${prefix}[{Request ID}]`
  );

/**
 * Removes `[GraphQL]` prefix from string.
 * @param string
 */
export const removeGraphqlPrefix = (string: string): string =>
  string.replaceAll(GRAPHQL_PREFIX_REGEX, '');

/**
 * Replace `gid://api/model/cec3e2b2-c856-4c8b-bc6a-dab759f6c60e` with `***`.
 * @param string
 */
export const replaceGids = (string: string): string =>
  string.replaceAll(GID_REGEX, '{GID}');

/**
 * Replace `cec3e2b2-c856-4c8b-bc6a-dab759f6c60e` with `***`.
 *
 * Ignores GIDs by using {@link ID_OR_GID_REGEX} and filtering the result if it matches {@link GID_PREFIX_REGEX}.
 *
 * @param string
 */
export const replaceIds = (string: string): string =>
  string.replaceAll(ID_OR_GID_REGEX, (substring) => {
    if (substring.match(GID_PREFIX_REGEX)) return substring;

    return '{ID}';
  });

/**
 * Map of all Identifier modifiers.
 */
export const MODIFIERS = new Map([
  ['requestId', replaceRequestIds],
  ['graphqlPrefix', removeGraphqlPrefix],
  ['gid', replaceGids],
  ['id', replaceIds],
] as const);

/**
 * Execute all {@link MODIFIERS|modifiers} to modify the identifiers of an error message.
 *
 * It uses the following modifiers:
 * - {@link replaceRequestIds}
 * - {@link removeGraphqlPrefix}
 * - {@link replaceGids}
 * - {@link replaceIds}
 *
 * @param string Error message
 */
export const modifyIdentifiers = (string: string): string =>
  Array.from(MODIFIERS).reduce((previous, [, fn]) => fn(previous), string);

/**
 * Sanitizes the error message by removing sensitive information.
 * Used for modifying errors that are reported to AppSignal.
 */
export const sanitizeErrorMessage = <T extends Error>(error: T): T => {
  /*
   * This `try/catch` is to make sure that we don't do this on a
   * non-error object and/or don't fail just because the error is not as
   * expected.
   *
   * We aren't always perfectly checking that, and it's also not possible
   * to enforce in runtime. Theoretically, you can throw any primitive just
   * as well as an error class. We also sometimes just type assert
   * to error, i.e. in
   * https://github.com/hausgold/maklerportal-next-frontend/blob/d18b9f9aa763f6ca88c563d66af07f86130314db/src/pages/lead/components/LeadSidebar/Content/CancelModal/CancelModal.tsx
   */
  try {
    /*
     * For safety reasons, string checks are performed. At runtime, type definitions
     * may not always match; error.message and stack can be undefined.
     */
    return Object.defineProperties(error, {
      message: {
        value:
          error.message && typeof error.message === 'string'
            ? modifyIdentifiers(error.message)
            : error.message,
      },
      stack: {
        value:
          error.stack && typeof error.stack === 'string'
            ? modifyIdentifiers(error.stack)
            : error.stack,
      },
    });
  } catch (e) {
    if (isLocal) {
      // eslint-disable-next-line no-console
      console.error('Error: Sanitization of error message failed.', e);
    }

    return error;
  }
};

// List of keys which may contain user sensitive data.
const SensitiveKeys = {
  gender: 'gender',
  firstName: 'firstName',
  lastName: 'lastName',
  name: 'name',
  email: 'email',
  emails: 'emails',
  phoneNumbers: 'phoneNumbers',
  mobile: 'mobile',
  reachability: 'reachability',
  city: 'city',
  postalCode: 'postalCode',
  street: 'street',
  streetAddition: 'streetAddition',
  saleReason: 'saleReason',
  additionalInfo: 'additionalInfo',
  evaluationReason: 'evaluationReason',
  filename: 'filename',
  title: 'title',
  description: 'description',
  comment: 'comment',
  reason: 'reason',
};

export const anonymiseData = (data: object) => {
  // Parse the object to a string.
  let dataString = JSON.stringify(data);

  // Loop through the sensitive keys and anonymise them.
  Object.keys(SensitiveKeys).forEach((key) => {
    // "Positive Lookbehind" which takes the string after the given key surrounded by double quotes.
    const pattern = `(?<="${key}":)"[^"]*"`;
    const re = new RegExp(pattern, 'g');
    // Replace the sensitive data by three stars.
    dataString = dataString.replace(re, '"***"');
  });

  // Parse the object-string back to a real object.
  const transformedData = JSON.parse(dataString);

  // "Drop" whole `stateProperties` object if present.
  if (transformedData?.stateProperties) {
    transformedData.stateProperties = '***';
  }

  return transformedData;
};
