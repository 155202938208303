// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-closed-lead-closed
const leadClosed = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:closed';

  stanzas.define({
    name: 'comment',
    element: 'comment',
    namespace: NS,
    fields: {
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'lead_closed',
    element: 'lead_closed',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      owner: types.textSub('owner'),
      created_at: types.textSub('created_at'),
      comment: types.textSub('comment'),
    },
  });
};

export default leadClosed;
