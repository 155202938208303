module.exports = {
  overrides: [
    {
      files: '**/*.test.{js,jsx,ts,tsx}',
      plugins: ['testing-library'],
      rules: {
        /**
         * Allows imports from `devDependencies`.
         * https://github.com/import-js/eslint-plugin-import/blob/main/docs/rules/no-extraneous-dependencies.md
         */
        'import/no-extraneous-dependencies': 'off',
      },
    },
  ],
};
