export const isBrowser = typeof window !== 'undefined';

/**
 * Returns whether the current browser runs on iOS device or not.
 * @returns {boolean}
 * @see https://stackoverflow.com/a/9039885
 * Note: `navigator.platform` is deprecated but there is no real alternative at the moment.
 */
export const isIOS = () => {
  if (!isBrowser) {
    return false;
  }

  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // IPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

/**
 * Returns whether the current browser is firefox or not.
 * @returns {boolean}
 * @see https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
 */
export const isFirefox = () =>
  isBrowser && navigator.userAgent.indexOf('Firefox') > -1;
/**
 * Returns whether the current browser is chrome or not.
 * @returns {boolean}
 * @see https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
 */
export const isChrome = () =>
  isBrowser && navigator.userAgent.indexOf('Chrome') > -1;
/**
 * Returns whether the current browser is safari or not.
 * @returns {boolean}
 * @see https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
 */
export const isSafari = () =>
  isBrowser && navigator.userAgent.indexOf('Safari') > -1 && !isChrome();

/**
 * Returns whether the current browser runs on Mac device or not.
 * @returns {boolean}
 * @see https://stackoverflow.com/a/11752084
 */
export const isMac = () =>
  isBrowser && navigator.platform.toUpperCase().indexOf('MAC') >= 0;

/**
 * Returns whether the current browser runs on android device or not.
 * @returns {boolean}
 * @see https://stackoverflow.com/a/6031480
 */
export const isAndroid = () =>
  isBrowser && navigator.userAgent.toLowerCase().indexOf('android') > -1;
