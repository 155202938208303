import { motion } from 'framer-motion';
import { Box } from '@hausgold/designsystem';
import PropTypes from 'prop-types';
import { SIDEBAR_WIDTH } from 'app-utils/constants/ui';

/**
 * Transition definition
 *
 * Matches the `<Drawer />` component, which internally uses DS/Chakra UI's
 * `<Slide />`.
 *
 * Definition is from `<Slide />`.
 *
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/transition/src/slide.tsx#L21-L31
 */
const Transition = {
  exit: {
    duration: 0.15,
    ease: [0.4, 0, 0.2, 1],
  },
  enter: {
    type: 'spring',
    damping: 25,
    stiffness: 180,
  },
};

const Animation = {
  enter: {
    transform: `translateX(${SIDEBAR_WIDTH})`,
  },
  exit: {
    transform: 0,
  },
};

/**
 * PageSlide
 *
 * Animated component to specifically slide the page to the right, matched in
 * speed and direction to the mobile sidebar (inside `<Drawer />`).
 *
 * @param {Object} props
 * @param {boolean=false} props.in Show the component; triggers the enter or exit states
 * @param {import('@hausgold/designsystem').BoxProps} rest
 */
const PageSlide = ({ in: inProp, ...rest }) => (
  <Box
    {...rest}
    as={motion.div}
    initial="exit"
    exit="exit"
    animate={inProp ? 'enter' : 'exit'}
    variants={{
      exit: {
        ...Animation.exit,
        transition: Transition.exit,
      },
      enter: {
        ...Animation.enter,
        transition: Transition.enter,
      },
    }}
  />
);

PageSlide.propTypes = {
  in: PropTypes.bool,
};

PageSlide.defaultProps = {
  in: false,
};

export default PageSlide;
