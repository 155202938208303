module.exports = {
  extends: [require.resolve('./native')],
  plugins: ['jsx-a11y'],
  rules: {
    /*
     * Enforce state initialization styldocs
     * https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/state-in-constructor.md
     */
    'react/state-in-constructor': ['error', 'never'],

    /*
     * Forbid certain propTypes
     * https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/forbid-prop-types.md
     */
    'react/forbid-prop-types': 'off',

    /*
     * Disallow JSX props spreading
     * https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-props-no-spreading.md
     */
    'react/jsx-props-no-spreading': 'off',

    /*
     * Enforce shorthand or standard form for React fragments
     * https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-fragments.md
     */
    'react/jsx-fragments': ['error', 'syntax'],

    /*
     * Checks hooks for dependencies and warns about potentially missing ones
     * https://reactjs.org/docs/hooks-rules.html
     */
    'react-hooks/exhaustive-deps': 'warn',

    /*
     * Checks if the used anchor is valid regarding accessibility rules
     * https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
     * We set `specialLink: 'as'` to allow usages of the `<Link as="button" />` component from
     * @hausgold/designsystem.
     */
    'jsx-a11y/anchor-is-valid': [
      'error',
      {
        specialLink: ['as'],
      },
    ],

    /*
     * Enforce arrow functions for React component definitions.
     * https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/function-component-definition.md
     */
    'react/function-component-definition': [
      'error',
      {
        namedComponents: 'arrow-function',
        unnamedComponents: 'arrow-function',
      },
    ],
  },
};
