import Appsignal from '@appsignal/javascript';
import { config } from 'app-config';
import { plugin as breadcrumbsNetworkPlugin } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as breadcrumbsConsolePlugin } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as pathDecoratorPlugin } from '@appsignal/plugin-path-decorator';
import { isBrowser } from 'app-utils/isEnv';

/*
 * Make sure AppSignal is deactivated locally (and server-side), even when using
 * different app or API configurations. If not, use the usual config.
 */
const IS_ACTIVE =
  global?.location?.href?.includes('.local') || !isBrowser
    ? false
    : config.appsignal?.active;

const appsignal = new Appsignal({
  active: IS_ACTIVE,
  key: config.appsignal.key,
  revision: config.appsignal.revision,
  // Spans which will be logged in console but not send to AppSignal as this are no real errors.
  ignoreErrors: [
    /Insufficient permissions for given action/,
    /The provided JWT is invalid/,
    /HTTP Token: Access denied/,
    // Offline errors which are all the same but each browser reports it differently.
    /NetworkError when attempting to fetch resource\./, // FireFox
    /Failed to fetch/, // Chrome + Samsung Browser
    /Load failed/, // Safari
  ],
});

// Add branch to each error
appsignal.addDecorator((span) =>
  span.setTags({
    branch: config.appsignal.branch,
  })
);

/*
 * Deactivate any plugins if AppSignal is not active. The plugins obfuscate
 * where `console.log`'s came from by hijacking it.
 */
if (IS_ACTIVE) {
  appsignal.use(breadcrumbsNetworkPlugin());
  appsignal.use(breadcrumbsConsolePlugin());
  appsignal.use(pathDecoratorPlugin());
}

/**
 * All used categories for breadcrumbs
 * Note: Still WIP list. Feel free to add more as we are still adding features.
 * https://docs.appsignal.com/front-end/breadcrumbs.html#usage
 */
export const CATEGORIES = {
  UI: 'UI',
  Network: 'Network',
  Navigation: 'Navigation',
  Console: 'Console',
};

/**
 * All used namespaces for spans
 * Note: Still WIP list. Feel free to add more as we are still adding features.
 * https://docs.appsignal.com/guides/namespaces.html
 * https://docs.appsignal.com/front-end/span.html#span-setnamespace-name-string
 */
export const NAMESPACES = {
  GraphQL: 'GraphQL',
};

export default appsignal;
