import { useContext, useEffect, useMemo, useState } from 'react';
import { JabberContext } from 'app-components/jabber/JabberProvider';
import {
  brokerIdToJid,
  formatRawMessage,
  getStanzaTranslation,
  leadIdToJid,
} from 'app-components/jabber/helper';
import { ID, useUserQuery } from 'app-graphql';
import { formatFullName } from 'app-services/models/gql/preferencesSet';
import { uniqWith } from 'lodash-es';
import {
  MamResult,
  MessageWithStanza,
  RawMessageOfMamResult,
  RawMessageOfSubscription,
} from 'app-components/jabber/messages';
import { useTranslation } from 'react-i18next';
import appsignal from 'app-lib/appsignal';
import { sanitizeErrorMessage } from 'app-utils/appsignal/sanitizeErrorMessage';

export type RoomMessages = {
  // Total amount of existing messages in this chat room.
  count: number;
  // Message id of the first received message.
  first: string;
  // Message id of the last received message.
  latest: string;
  items: MessageWithStanza[];
};

export interface UseRoomProps {
  jid?: string;
  /**
   * Amount (integer) of messages which should be fetched from history.
   * @default 500
   */
  amountHistoricMessages?: number;
}

/**
 * The useRoom hook connects to the `JabberProvider` and manage a single room,
 * which is defined by a given `jid`
 * by requesting old chat messages (history)
 * as well as subscribe / unsubscribe to the new-messages-listener-event.
 * Received messages will be parsed and ordered here. Also, the hook ensures
 * that no duplicates are shown which may occur because of the architectural nature
 * of event driven systems.
 *
 * Note: At the moment the hook is not able to manage paginated history messages
 * requests and is not able to send messages.
 */
const useRoom = ({ jid, amountHistoricMessages = 500 }: UseRoomProps) => {
  const [{ data }] = useUserQuery();
  const { t } = useTranslation(['common']);
  /*
   * Returns brokers name or fallback if no broker name is set.
   * Username is mandatory to join a chat room.
   */
  const userName =
    formatFullName(data?.broker?.preferences) ||
    (data?.broker && t('common:broker'));

  const {
    client,
    messages: newMessages,
    error: clientError,
    isRunning: isJabberRunning,
  } = useContext(JabberContext);

  const [messages, setMessages] = useState<RoomMessages | undefined>(undefined);
  // Room specific errors which may occur on requesting the history.
  const [error, setError] = useState<ErrorEvent | boolean | null>(null);
  const [loading, setLoading] = useState(true);

  /*
   * Merges old (history) and new (subscribed) messages together.
   * Also formats the messages and order them from youngest to oldest.
   */
  const mergeMessagesObject = (
    newMessageObject: MamResult | { items: RawMessageOfSubscription[] }
  ) => {
    const newMessagesFormatted =
      newMessageObject?.items
        ?.map((rawMessage) => {
          // @ts-ignore rawMessage is not 100% defined, but it does not matter here.
          const message = formatRawMessage(rawMessage);
          return getStanzaTranslation(message);
        })
        ?.reverse() || {};

    setMessages((oldMessages) => ({
      ...(oldMessages || {}),
      // @ts-ignore "Property 'rsm' does not exist on type '{ items: RawMessageOfSubscription[]; }'" <- This is fine, because of that it is optional chaining...
      ...newMessageObject?.rsm,
      // Formatted messages in reverse order (newest first)
      items: uniqWith(
        [...newMessagesFormatted, ...(oldMessages?.items || [])],
        // Id is a number or a string, so we compare them as strings.
        ({ id: existingId }, { id: newId }) =>
          existingId.toString() === newId.toString()
      ),
    }));
  };

  // Get old messages from given room if jid exists and jabber is running.
  useEffect(() => {
    if (client && isJabberRunning && jid) {
      // Get the past entries from chat history.
      const searchOption = {
        jid,
        rsm: {
          before: true,
          complete: false,
          max: amountHistoricMessages,
        },
      };

      // Request the history (old messages) of the room and save those messages returned in callback.
      client.searchHistory(
        searchOption,
        (err: ErrorEvent, historyData: RawMessageOfMamResult) => {
          if (err) {
            appsignal.send(
              appsignal.createSpan((span) => {
                span.setAction('Jabber room error on requesting the history.');
                span.setTags({
                  jid,
                  text: err.error.text,
                  condition: err.error.condition,
                  code: err.error.code,
                  // @ts-ignore
                  from: err.from.bare,
                  // @ts-ignore
                  to: err.to.bare,
                  // @ts-ignore
                  id: err.id,
                });
                span.setError(sanitizeErrorMessage(err.error));
              })
            );

            setError(err);
          } else if (historyData?.mamResult) {
            mergeMessagesObject(historyData.mamResult);
            setError(null);
          }

          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jid, client, isJabberRunning]);

  // Subscribe / Unsubscribe the user to this room to get new messages.
  useEffect(() => {
    if (client && isJabberRunning && jid && userName) {
      client.joinRoom(jid, userName, {
        joinMuc: {
          /*
           * Do not request the last messages on joining the room as `searchHistory` handles them already.
           * For the current version "gimme the last 500" it would be fine to only have this one.
           * However, the main functionality of `joinRoom` is the subscription to the room which adds event handlers.
           * Incoming messages are handled by those event handlers within the `JabberProvider` which makes the
           * messages handling more complicated as for `searchHistory` which is a simple request-response.
           * At the latest when we are adding pagination, the `searchHistory` becomes mandatory anyway,
           * so we act here like the pagination is not set to "last 500" already.
           */
          history: {
            maxstanzas: 0,
          },
        },
      });
    }

    // Unsubscribe the room when leaving.
    return () => {
      if (client && isJabberRunning && jid && userName) {
        client.leaveRoom(jid, userName);
      }
    };
  }, [jid, client, isJabberRunning, userName]);

  // Lint rules suggest to have a distinct variable for to make the useEffect check easier.
  const newMessagesLength = jid && newMessages?.[jid]?.length;

  // Handles new incoming messages and includes them into `messages` array.
  useEffect(() => {
    if (jid && newMessages?.[jid]) {
      mergeMessagesObject({ items: newMessages[jid] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jid, newMessagesLength]);

  /*
   * There is an edge case in development where `client.searchHistory()`
   *   does not return anything which results in an endless loading spinner.
   *
   * This fallback ensures the user does not see the endless loading spinner.
   * Only occurred on dev mode so far (edge case) but safe is safe.
   *
   * To reproduce:
   *     1. Open Logbook tab (messages loads).
   *     2. Change code which is not related to Logbook / Jabber like.
   *       adding a space somewhere in `_app.page.jsx`.
   *     3. Go back to the browser (next.js will apply the "change" via hot module replacer (HMR)).
   *     4. Switch to another tab like Team.
   *     5. Switch back to Logbook tab.
   *     -> Endless loading as this useEffect runs but result never comes.
   */
  useEffect(() => {
    let errorTimer: NodeJS.Timeout | undefined;
    if (loading && !messages) {
      errorTimer = setTimeout(() => {
        setLoading(false);
        setError(true);
      }, 20000);
    }

    return () => errorTimer && clearTimeout(errorTimer);
  }, [loading, messages]);

  // Combine jabber errors and room errors.
  const hasError = error || clientError;
  // Show loading state only if no error is set.
  const isLoading = loading && !hasError;

  return { error: hasError, loading: isLoading, messages };
};

export interface UseLeadRoomProps extends UseRoomProps {
  leadId?: ID;
}

/**
 * Wraps the general useRoom hook to join a given lead chat with its ID.
 */
const useLeadRoom = ({ leadId, ...rest }: UseLeadRoomProps) => {
  const { clientConfig } = useContext(JabberContext);

  // The jid is the "GID" of jabber.
  const jid = useMemo(() => {
    // Creates the jid when all dependent data exists.
    if (leadId && clientConfig?.mucHost) {
      // This jid is for joining a room related to a single lead.
      return leadIdToJid(leadId, clientConfig?.mucHost);
    }

    return undefined;
  }, [leadId, clientConfig?.mucHost]);

  return useRoom({ ...rest, jid });
};

export interface UseBrokerRoomProps extends UseRoomProps {
  /**
   * Brokers id used to create a jid from
   */
  brokerId?: ID;
}

/**
 * Wraps the general useRoom hook to join a given broker chat with its ID.
 */
const useBrokerRoom = ({ brokerId, ...rest }: UseBrokerRoomProps) => {
  const { clientConfig } = useContext(JabberContext);

  // The jid is the "GID" of jabber.
  const jid = useMemo(() => {
    if (brokerId && clientConfig?.mucHost) {
      // This jid is for joining the room where all leads of this broker are in.
      return brokerIdToJid(brokerId, clientConfig?.mucHost);
    }

    return undefined;
  }, [brokerId, clientConfig?.mucHost]);

  return useRoom({ ...rest, jid });
};

export { useBrokerRoom, useLeadRoom };
