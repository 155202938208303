import { differenceInCalendarDays, differenceInMinutes } from 'date-fns';
import { floor } from 'lodash-es';
import i18n from 'app-utils/i18n';

/**
 * Holds time-based breaking points for defining the relative pastime.
 */
const TIMES = {
  // Number of ms for a second.
  SECOND: 1000,
  // Number of seconds for a minute.
  MINUTE: 60,
  // Number of minutes for an hour.
  HOUR: 60,
  // Number of minutes for a day.
  DAY: 1440,
  // Number of days for a week.
  WEEK: 7,
};

/**
 * Returns a text based "remaining time" in minutes, hours, days and weeks from one date relative to the other.
 */
const getTimeGapText = (
  firstTime: number | Date,
  secondTime: number | Date,
  showRelativeNow = false
) => {
  /*
   * Difference in days. Note that days are rounded up / not respect time difference.
   * 2024/03/02:23:59:59 vs 2024/03/03:00:00:01 would be one day difference even if it is 2 seconds only.
   */
  const days = differenceInCalendarDays(firstTime, secondTime);
  let minutes;

  // Calculate difference in minutes if only one day past.
  if (days < 2) {
    minutes = differenceInMinutes(firstTime, secondTime);
  }

  // If minutes were calculated, try to match the time difference.
  if (minutes !== undefined) {
    if (minutes >= 0 && minutes < 1) {
      if (showRelativeNow) {
        return i18n.t('common:now');
      }

      return `${minutes} ${i18n.t('common:minute', {
        count: minutes,
      })}`;
    }

    if (minutes >= 1 && minutes < TIMES.HOUR) {
      return `${minutes} ${i18n.t('common:minute', {
        count: minutes,
      })}`;
    }

    if (minutes >= TIMES.HOUR && minutes < TIMES.DAY) {
      const hours = floor(minutes / TIMES.HOUR);
      return `${hours} ${i18n.t('common:hour', {
        count: hours,
      })}`;
    }
  }

  if (days >= 0 && days < TIMES.WEEK) {
    return `${days} ${i18n.t('common:day', {
      count: days || 1,
    })}`;
  }

  // Else try to match the date difference.
  if (days >= TIMES.WEEK) {
    const weeks = floor(days / TIMES.WEEK);
    return `${weeks} ${i18n.t('common:week', {
      count: weeks,
    })}`;
  }

  return null;
};

/**
 * Format a given timestamp to a relative time from now where the timestamp should be in the past.
 * This can reach from "now" to "x weeks".
 */
const computeRelativeDate = (timestamp: string) => {
  const timeGapText = getTimeGapText(Date.now(), new Date(timestamp), true);

  return timeGapText || i18n.t('common:notSpecified');
};

/**
 * Format a given timestamp to a relative time from now where the timestamp should be in the future.
 * This can reach from "x minutes" to "x weeks".
 */
const computeRemainingTime = (timestamp: string) => {
  const timeGapText = getTimeGapText(new Date(timestamp), Date.now());

  return timeGapText;
};

export { computeRelativeDate, computeRemainingTime };
