// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#appointment-alarmreminder
const appointmentAlarm = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:appointment:alarm';

  stanzas.define({
    name: 'start_at',
    element: 'start_at',
    namespace: NS,
    fields: {
      format: types.attribute('format'),
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'end_at',
    element: 'end_at',
    namespace: NS,
    fields: {
      format: types.attribute('format'),
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'appointment_alarm',
    element: 'appointment_alarm',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      owner: types.textSub('owner'),
      title: types.textSub('title'),
      description: types.textSub('description'),
      start_at: types.textSub('start_at'),
      end_at: types.textSub('end_at'),
    },
  });
};

export default appointmentAlarm;
