import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useOutsideClick,
} from '@hausgold/designsystem';
import { Magnifier } from '@hausgold/designsystem/icons';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import usePWADisplayMode from 'app-utils/hooks/usePWADisplayMode';

/**
 * Search icon as button used for toggling the
 * search on mobile and submitting the form when present
 *
 * @param {import('@hausgold/designsystem').BoxProps} iconProps
 * @param {import('@hausgold/designsystem').IconButtonProps} rest
 */
const SearchIconButton = ({ iconProps, ...rest }) => (
  <IconButton variant="unstyled" lineHeight={0} {...rest}>
    <Magnifier {...iconProps} />
  </IconButton>
);

SearchIconButton.propTypes = {
  iconProps: PropTypes.object,
};

SearchIconButton.defaultProps = {
  iconProps: null,
};

/**
 * Header search componen
 *
 * @param {function} onOpen Opens the search input
 * @param {function} onClose Closes the search input
 * @param {boolean} isOpen States if the search input is visible or not
 * @param {import('@hausgold/designsystem').BoxProps} props Additional properties passed to form
 */
const Search = ({ onOpen, onClose, isOpen, ...props }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isWindowControlsOverlay } = usePWADisplayMode();
  const [searchQuery, setSearchQuery] = useState('');

  const ref = useRef();
  useOutsideClick({
    ref,
    handler: onClose,
  });

  /**
   * Set `searchQuery` as default input value if
   * a `searchQuery` value ist found
   */
  useEffect(() => {
    setSearchQuery(router?.query?.searchQuery || '');
  }, [router.isReady, router?.query?.searchQuery]);

  /**
   * Handle submit and show search results
   */
  const handleSubmit = (event) => {
    event.preventDefault();

    router.push({
      pathname: '/search',
      query: {
        searchQuery,
      },
    });
  };

  /**
   * Open input search on mobile on click
   * of search icon
   */
  const handleSearchIconClick = (event) => {
    event.preventDefault();
    onOpen();
  };

  /**
   * Handle change of search query
   */
  const handleQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const wcoInputStyles =
    {
      transition: 'height .5s',
      ...(isWindowControlsOverlay && {
        paddingStart: 1,
        height: 'calc(env(titlebar-area-height, 28px) - 4px)',
      }),
    } || {};

  const wcoInputElementStyles =
    (isWindowControlsOverlay && {
      height: 'calc(env(titlebar-area-height, 28px) - 4px)',
    }) ||
    {};

  return (
    <Box
      ref={ref}
      as="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{ appRegion: 'no-drag' }}
      {...props}
    >
      <FormControl
        m={0}
        id="query"
        name="query"
        isRequired
        display={
          isOpen
            ? 'block'
            : {
                base: 'none',
                md: 'block',
              }
        }
      >
        <InputGroup size={isWindowControlsOverlay ? '' : 'xs'}>
          <Input
            value={searchQuery}
            onChange={handleQueryChange}
            placeholder={t('search.placeholder')}
            data-testid="searchInput"
            // Fontsize needs to be 16px even if the "rest-size" from InputGroup is 'xs'.
            fontSize="lg"
            // As InputRightElement is "absolute" over the input, it covers a part of the visible space. This adds more (10px) space for.
            width="235px"
            {...wcoInputStyles}
          />
          <InputRightElement padding={0} {...wcoInputElementStyles}>
            <SearchIconButton
              type="submit"
              color="gray.400"
              height="100%"
              /*
               * I want paddingX=2 and paddingY=0, however padding is set by <IconButton/> itself (padding=0.5).
               * To overwrite it paddingX=2 does not work as this result into padding-inline-start/end
               * which is less important than padding and padding-left/right.
               * Regarding padding-top/bottom it seems to be even less important than padding,
               * so setting it to any number does remain it on the current default 0.5.
               * This is why I set the general padding to 0 for.
               * So, property prioritization seems to be shuffled to paddingLeft/Right > padding > paddingTop/Bottom > paddingX/Y.
               * Thanks Chakra-ui!
               */
              padding={0}
              paddingLeft={2}
              paddingRight={2}
              _hover={{
                color: 'gray.400',
              }}
              aria-label={t('search.placeholder')}
              data-testid="searchSubmitButton"
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <SearchIconButton
        display={
          isOpen
            ? 'none'
            : {
                base: 'block',
                md: 'none',
              }
        }
        _hover={{
          color: 'inherit',
        }}
        aria-label={t('search.placeholder')}
        data-testid="searchToggle"
        color="white"
        iconProps={{
          boxSize: 5,
        }}
        onClick={handleSearchIconClick}
      />
    </Box>
  );
};

Search.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

Search.defaultProps = {
  isOpen: false,
};

export default Search;
