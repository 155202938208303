import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
// @ts-ignore There are no @types/stanza for this version.
import { createClient } from 'stanza';
import * as Extensions from 'app-components/jabber/extensions';
import * as Stanzas from 'app-components/jabber/stanzas';
import { userIdToJid } from 'app-components/jabber/helper';
import { ID } from 'app-graphql';
import {
  RawMessage,
  RawMessageOfSubscription,
} from 'app-components/jabber/messages';
import appsignal from 'app-lib/appsignal';
import { v4 as uuidv4 } from 'uuid';

/**
 * This is (partial) copied from `stanza` v12 to satisfy TS
 * and shows methods of `stanza` which we are using.
 */
export type Client = {
  connect(opts?: JabberConfig): void;
  disconnect(): void;
  sendPresence(): string;
  searchHistory: (...arg: any[]) => unknown;
  joinRoom: (jid: string, userName: string, params: object) => void;
  leaveRoom: (jid: string, userName: string) => void;
  /**
   * Unique id which distinguish the client instance from other client instances.
   */
  runtimeId: string;
};

/**
 * The list of possible fields and their values is not complete,
 * but this is what we set and need for our purpose.
 */
export type JabberConfig = {
  // Transports can also be 'bosh' but is not supported by us.
  transports: 'websocket';
  // Handles the websocket like a stream which can be retried for example or not.
  useStreamManagement?: boolean;
  // The URL of the websocket.
  wsURL: string;
  // The "domain" of a user used to create a Jid.
  userHost: string;
  // The "domain" of a room used to create a Jid. (muc = Multi User Chat)
  mucHost: string;
  // The id of the user used for Jid creation.
  userId: ID;
  // User session password.
  password: string | boolean;
  // The Jid of the user. Schema is `{userId}@{userHost}`.
  jid: string;
};
export interface JabberProviderProps {
  // The config for the jabber client (or parts of it).
  config?: JabberConfig;
  children: ReactNode;
}

const DEFAULT_CONFIG: Partial<JabberConfig> = {
  transports: 'websocket',
  useStreamManagement: true,
};

export type JabberContextProps = {
  // The stanza created client instance.
  client: Client | null;
  clientConfig: Partial<JabberConfig> | null;
  setClientConfig: (newConfig: Partial<JabberConfig>) => void;
  // Messages which are received because of a room subscription are hold here.
  messages: MessageObject;
  error: String | ErrorEvent | null;
  // Defines if the jabber is running and successful connected.
  isRunning: boolean;
};

const JabberContext = createContext<JabberContextProps>({
  client: null,
  clientConfig: null,
  setClientConfig: () => {},
  messages: {},
  error: null,
  isRunning: false,
});

/*
 * The MessageObject is an object of subscribed room jids
 * containing their last messages received from the subscription event handler.
 */
export type MessageObject = {
  [roomJid: string]: RawMessageOfSubscription[];
};

/**
 * The JabberProvider handles the `stanza`-client which connects to
 * the ejabberd XMPP Server used for real time communication.
 * As `stanza` is event-driven and massively in lack of documentation,
 * the provider takes special effort in handling all relevant events
 * as well as in describing why and what is done.
 *
 * While this part, the JabberProvider, handles the connection itself,
 * ejabberd works with chat(-rooms). To actually get messages you need to join
 * such a room you are interested in. This is managed by another part - the [useRoom-hook]{@link useRoom}.
 *
 * Mainly the provider ensures that
 * - the `stanza` config is set properly, based on multiple possible configuration sources.
 * - the `stanza` builds up a connection to backend via websocket.
 *   - Preventing multiple connections at the same time.
 *   - If possible, the connection will be restored if lost.
 *   - Disconnects if the user logs out / the config becomes incomplete.
 * - `stanza`'s relevant events are caught and handled.
 * - the context for hooks is up-to-date.
 *
 * Note: Compared to the previous `stanza` implementation, located at `@hausgold/react-jabber`
 * we're currently missing some features like sending chat messages and
 * listen to non-lead-rooms.
 *
 * @example
 *
 * ```
 * // The JabberProvider needs to be included on root level.
 * // If the whole config can be provided here, we are already done.
 *
 * import JabberProvider from 'app-components/jabber/JabberProvider';
 * import { config } from 'app-config';
 *
 * const RootComponent = () => {
 *  return {
 *    <JabberProvider config={config.jabber}>
 *      // Further Content
 *    </JabberProvider>
 *  }
 * }
 *
 * // ...
 *
 * // If some config is missing on root level, e.g. user credentials, you need to call
 * the configuration hook later on in the child-component of the JabberProvider, where you have access to these config parameters:
 *
 * import { JabberContext } from 'app-components/jabber/JabberProvider';
 *
 * const ChildComponent = () => {
 *  const { setClientConfig } = useContext(JabberContext);
 *  setClientConfig({ userId: '123, password: '456' });
 *
 *  // ...
 * }
 * ```
 */
const JabberProvider = ({
  config: configProps,
  children,
}: JabberProviderProps) => {
  const [clientConfig, setClientConfig] =
    useState<Partial<JabberConfig>>(DEFAULT_CONFIG);
  const [client, setClient] = useState<Client | null>(null);
  const [messages, setMessages] = useState<MessageObject>({});
  const [error, setError] = useState<String | ErrorEvent | null>(null);
  const [isClientRunning, setIsClientRunning] = useState(false);

  // Updates the jabber config by merging the current existing one with the new given.
  const setJabberConfig = (newConfig: Partial<JabberConfig>) => {
    setClientConfig((oldConfig) => {
      // Merge current config with new one.
      const formattedConfig = { ...oldConfig, ...newConfig };

      // Construct and add `jid` if possible.
      if (formattedConfig.userId && formattedConfig.userHost) {
        formattedConfig.jid = userIdToJid(
          formattedConfig.userId,
          formattedConfig.userHost
        );
      }

      /*
       * Remove jid if user or userConfig is missing.
       * E.g. after logout.
       */
      if (!formattedConfig.userId || !formattedConfig.userHost) {
        delete formattedConfig.jid;
      }

      return formattedConfig;
    });
  };

  // Updates saved config object if given props-config changed.
  useEffect(() => {
    if (configProps) {
      setJabberConfig(configProps);
    }
  }, [configProps]);

  // Stops the websocket connection and unset the client.
  const stopClient = () => {
    client?.disconnect();
    setClient(null);
    setIsClientRunning(false);
  };

  // Handles incoming messages and ensure the message stack becomes not too big.
  const onNewMessage = (rawMessage: RawMessage) => {
    /*
     * Ignore messages...
     * - with `mamItem`. Messages with `mamItem` are messages retrieved from
     *   `client.searchHistory`. We already handle them in `.searchHistory()` from useRoom-hook.
     * - with `chatState`. They indicate typing.
     * - with `subject` attribute. They are system messages used for room
     *   subject changes.
     * - with `muc` object attribute. They are system messages used for room
     *   affiliations (user get invited to room).
     */
    if (
      // @ts-ignore "Property 'mamItem' does not exist on type 'RawMessageOfSubject'." <- Exactly what we want!
      rawMessage.mamItem ||
      // @ts-ignore I have no clue how this object would look like, and we do not need it anyway.
      rawMessage.chatState ||
      // @ts-ignore "Property 'subject' does not exist on type 'RawMessageOfMamItem'." <- Exactly what we want!
      rawMessage.subject ||
      // @ts-ignore I have no clue how this object would look like, and we do not need it anyway.
      rawMessage.muc
    ) {
      return;
    }

    // Take care of sorting the message, so hooks can focus on relevant messages only.
    setMessages((oldMessages) => {
      const messageJid: string = rawMessage.from.bare;
      // Old messages array or empty array.
      let roomMessages = oldMessages[messageJid] || [];

      /*
       * Prevent adding duplicates. This can happen e.g. if a room is subscribed multiple times.
       * Messages are compared by either `id` or `stanzaId` depending on the message type.
       */
      if (
        !roomMessages.some((roomMessage) => {
          if (roomMessage.id) {
            // @ts-ignore "Property 'id' does not exist on type 'RawMessageOfSubject'." Subject was filtered out above already...
            return roomMessage.id === rawMessage?.id;
          }

          // @ts-ignore "Property 'id' does not exist on type 'RawMessageOfSubject'." Subject was filtered out above already...
          return roomMessage.stanzaId === rawMessage?.stanzaId;
        })
      ) {
        roomMessages.push(rawMessage as RawMessageOfSubscription);
      }

      // If too many messages are in here, we drop the 10 oldest to stay fast.
      if (roomMessages.length > 20) {
        roomMessages = roomMessages.slice(10);
      }

      // New messages object including the new message.
      return {
        ...oldMessages,
        [messageJid]: roomMessages,
      };
    });
  };

  // Send presence / online state on a regular basis if connected, so the user stays online.
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    // Resend presence every 30s while client itself is connected.
    if (isClientRunning) {
      intervalId = setInterval(() => {
        if (client && isClientRunning) {
          client.sendPresence();
        }
      }, 30000);
    }

    // Stops the timer if running.
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, isClientRunning]);

  /*
   * Creates the stanza client instance with given (complete) config,
   * so it is ready to use.
   * Besides the config itself, it is needed to set several more things
   * like extensions to use our custom stanzas and event listener for message and connection handling.
   */
  const createJabberInstance = (newConfig: JabberConfig) => {
    const newClient = createClient(newConfig);

    // Apply every extension
    Object.keys(Extensions).forEach((key) => {
      // @ts-ignore With stanza v12 those needs to be reworked anyway.
      newClient.use(Extensions[key]);
    });

    // Apply every stanza
    Object.keys(Stanzas).forEach((key) => {
      // @ts-ignore With stanza v12 those needs to be reworked anyway.
      newClient.use(Stanzas[key]);
    });

    /*
     * `stanza` has several state events which can be listened on.
     * The first events after starting are:
     * 1. connected
     * 2. auth:success
     * 3. session:started
     *
     * Only after the third step the client is ready to do things like joining rooms and receiving messages.
     * If such an action is requested before step 3 the whole connection aborts.
     */
    newClient.on('session:started', () => {
      setError(null);
      setIsClientRunning(true);
      /*
       * Carbon is used to spread the message from an owner to other devices
       * of the same owner when both/all devices are logged in at the same time.
       */
      newClient.enableCarbons();
    });

    // Flag that remembers if we already sent an error report. This is needed to prevent that the retry will send another report.
    newClient.errorReportSend = {
      authFailed: false,
      sessionError: false,
    };

    // Propagate error if auth fails.
    newClient.on('auth:failed', () => {
      // Unfortunately jabber lib does not communicate helpful stuff, so we just have the basic information here.
      if (!newClient.errorReportSend.authFailed) {
        appsignal.send(
          appsignal.createSpan((span) => {
            span.setAction('Jabber client error: auth failed.');
          })
        );
      }

      newClient.errorReportSend.authFailed = true;
      setError('authFailed');
    });

    /*
     * Propagate client stop if the client disconnects.
     * This can happen on fire `client.disconnect()`, as well as after errors like `auth:failed`.
     */
    newClient.on('disconnected', () => {
      setIsClientRunning(false);
    });

    /*
     * Propagate error if the sessions errors.
     * Note: Never saw this, so I'm unsure if this is really relevant.
     */
    newClient.on('session:error', () => {
      // Unfortunately jabber lib does not communicate helpful stuff, so we just have the basic information here.
      if (!newClient.errorReportSend.sessionError) {
        appsignal.send(
          appsignal.createSpan((span) => {
            span.setAction('Jabber client error: session error.');
          })
        );
      }

      newClient.errorReportSend.sessionError = true;

      setError('sessionError');
    });

    /*
     * Propagate error if the sessions end.
     * Note: Never saw this, so I'm unsure if this is really relevant.
     */
    newClient.on('session:end', () => {
      setError('sessionEnd');
    });

    /*
     * 'message' is triggered on every message which is received, no matter whether it is a
     * - chat message
     * - system message
     * - historical (requested) message
     * - room service message
     * - etc.
     */
    newClient.on('message', (rawMessage: RawMessage) => {
      onNewMessage(rawMessage);
    });

    // Add an id to the created client to distinguish between different client instances.
    newClient.runtimeId = uuidv4();

    // Saves the client so we can work with.
    setClient(newClient);
  };

  /*
   * Watches on config changes.
   * Trigger client creation if config is complete but client does not exist or stop it first if exist,
   * do nothing if config is complete and client already exists (no interesting changes)
   * and unset client if config is incomplete but client exists.
   */
  useEffect(() => {
    if (
      clientConfig.password &&
      clientConfig.jid &&
      clientConfig.mucHost &&
      clientConfig.userHost &&
      clientConfig.wsURL &&
      clientConfig.transports === 'websocket'
    ) {
      // If config is complete and client is not set, initialize the client.
      if (!client) {
        createJabberInstance(clientConfig as JabberConfig);
      }

      // If config is complete and the client is running but has an error, reset the client.
      if (client && error) {
        stopClient();
        createJabberInstance(clientConfig as JabberConfig);
      }

      /*
       * If config has changed, is still complete and client works fine we ignore the change.
       * E.g. on token refresh the old one will work fine without restart.
       */

      // If config is incomplete but the client is running, stop it. E.g. after logout.
    } else if (!clientConfig.jid && isClientRunning) {
      stopClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientConfig]);

  // Open stanza's websocket connection if client exists and is not connected already.
  const startJabberSession = () => {
    if (client && !isClientRunning) {
      client.connect();
    }
  };

  // Start jabber if client is (re-)set.
  useEffect(() => {
    if (client) {
      startJabberSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.runtimeId]);

  /*
   * Retry connecting jabber if something went wrong.
   * The retry is executed every 10s.
   */
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (error) {
      timeoutId = setTimeout(() => {
        startJabberSession();
      }, 10000);
    }

    // Clear up the timer.
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /*
   * Merge jabber context object including the client and config setter/getter.
   * Note: For (obs/se)-curity we remove the password field.
   */
  const providerValue = useMemo(
    () => ({
      client,
      setClientConfig: setJabberConfig,
      clientConfig: { ...clientConfig, password: !!clientConfig.password },
      messages,
      error,
      isRunning: isClientRunning,
    }),
    [client, clientConfig, messages, isClientRunning, error]
  );

  return (
    <JabberContext.Provider value={providerValue}>
      {children}
    </JabberContext.Provider>
  );
};

export default JabberProvider;
export { JabberContext };
