module.exports = {
  extends: [
    'airbnb',
    'airbnb/hooks',
    'airbnb-typescript',
    'plugin:import/typescript',
    require.resolve('./shared/react'),
  ],
  settings: {
    react: {
      version: 'detect',
    },
  },
};
