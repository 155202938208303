import { useEffect, useRef } from 'react';

/**
 * A declarative `setInterval`.
 *
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param {Function} callback The callback to run every interval.
 * @param {number|null} delay The interval delay in milliseconds. If passed `null`, the interval is paused.
 */
function useInterval(callback, delay = null) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return () => {};
  }, [delay]);
}

export default useInterval;
