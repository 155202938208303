/**
 * Replace specific information of a given url by generic placeholders to unify urls which have the same meaning.
 *
 * @example
 * /lead/a8d8dc70-0806-4cde-afd0-97493a9f633e/property -> /lead/:leadId/property
 * /lead/978759fe-7d7d-479c-8c5f-32dc9a5a7824/property -> /lead/:leadId/property
 */
const anonymizeUrl = (url: string) => {
  if (url?.startsWith('/lead/')) {
    return url.replace(/^\/lead\/.*\//, '/lead/:leadId/');
  }

  return url;
};

export { anonymizeUrl };
