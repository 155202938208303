module.exports = {
  extends: ['../../eslint-config/shared/native'],
  parser: '@typescript-eslint/parser',
  parserOptions: {
    project: 'tsconfig.json',
  },
  rules: {
    /**
     * Disable `no-underscore-dangle`. Use `@typescript-eslint/naming-convention` instead.
     * https://eslint.org/docs/latest/rules/no-underscore-dangle
     */
    'no-underscore-dangle': ['off'],

    /**
     * Define naming conventions for variables, functions, etc.
     * https://github.com/typescript-eslint/typescript-eslint/blob/main/packages/eslint-plugin/docs/rules/naming-convention.md
     */
    '@typescript-eslint/naming-convention': [
      'error',
      {
        selector: 'objectLiteralProperty',
        format: null,
      },
      {
        selector: 'default',
        format: ['camelCase', 'PascalCase'],
        leadingUnderscore: 'allowSingleOrDouble',
        trailingUnderscore: 'allow',
      },
      {
        selector: 'variable',
        format: ['PascalCase', 'camelCase', 'UPPER_CASE'],
        leadingUnderscore: 'allowSingleOrDouble',
        trailingUnderscore: 'allow',
      },
      {
        selector: 'typeLike',
        format: ['PascalCase'],
      },
    ],
  },
  overrides: [
    {
      files: ['*.d.ts'],
      rules: {
        /**
         * Do not enforce multiline comment style in declaration files.
         * https://eslint.org/docs/rules/multiline-comment-style
         */
        'multiline-comment-style': ['off'],
        /*
         * Do not enforce spaced comments in declaration files.
         * https://eslint.org/docs/rules/spaced-comment
         */
        'spaced-comment': ['off'],
        /*
         * Do not enforce capitalization of the first letter of a comment in
         * declaration files.
         * https://eslint.org/docs/rules/capitalized-comments
         */
        'capitalized-comments': ['off'],
      },
    },
  ],
  settings: {
    'import/parsers': {
      '@typescript-eslint/parser': ['.js', '.jsx', '.ts', '.tsx'],
    },
    'import/resolver': {
      typescript: {
        alwaysTryTypes: true,
      },
    },
  },
};
