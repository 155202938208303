// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-notary-date-reminder
const leadNotaryDateReminder = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:sale_notary_date_reminder';

  stanzas.define({
    name: 'sale_notary_date',
    element: 'sale_notary_date',
    namespace: NS,
    fields: {
      format: types.attribute('format'),
      value: types.text(),
    },
  });

  stanzas.define({
    name: 'lead_sale_notary_date_reminder',
    element: 'lead_sale_notary_date_reminder',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      task: types.attribute('task'),
      owner: types.textSub('owner'),
      sale_notary_date: types.textSub('sale_notary_date'),
    },
  });
};

export default leadNotaryDateReminder;
